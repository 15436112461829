"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ConMerger;
function ConMerger(conA, conB) {
    const prop = Object.getPrototypeOf(conB);
    const propertyNames = Object.getOwnPropertyNames(prop);
    for (const key of propertyNames) {
        if (key !== 'constructor' && !conA[key]) {
            conA[key] = prop[key];
        }
    }
    return conA;
}
