"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectFetchNextPage = exports.selectFeedSearch = exports.selectFeedType = exports.selectFeedChanel = exports.selectTwitch = exports.selectFeedPage = exports.selectFeedModal = exports.selectEnfOfFeed = exports.selectFeedRes = exports.selectFeedList = exports.selectNotification = exports.selectWeather = exports.selectEchoStore = exports.setTwitch = exports.setFeedSearch = exports.setFeedChanel = exports.setFeedType = exports.setEndOfFeed = exports.setFeedList = exports.setNotificationRead = exports.setFetchNextPage = exports.setFeedRes = exports.setFeetModalOpen = exports.setFeedPage = exports.setNotification = exports.setWeather = exports.echoState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
/**
 * ECHO STORE
 */
const initialEchoEnv = {
    weather: null,
    notification: null,
    twitch: null,
    feedList: null,
    endOfFeed: false,
    fetchNextPage: false,
    feedRes: null,
    feedPage: 0,
    feedSearch: undefined,
    feedModal: false,
    feedType: undefined,
    feedChanel: undefined,
};
exports.echoState = (0, toolkit_1.createSlice)({
    name: 'echo',
    initialState: initialEchoEnv,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setWeather: (state, action) => {
            state.weather = action.payload;
        },
        setFeedRes: (state, action) => {
            state.feedRes = action.payload;
        },
        setNotification: (state, action) => {
            state.notification = action.payload;
        },
        setTwitch: (state, action) => {
            state.twitch = action.payload;
        },
        setNotificationRead: (state, action) => {
            if (state.notification) {
                const cur = state.notification?.findIndex((el) => el.e_id === action.payload.e_id);
                if (cur !== -1) {
                    state.notification[cur].user_read = true;
                }
            }
        },
        setFeedList: (state, action) => {
            state.feedList = action.payload;
        },
        setEndOfFeed: (state, action) => {
            state.endOfFeed = action.payload;
        },
        setFetchNextPage: (state, action) => {
            state.fetchNextPage = action.payload;
        },
        setFeedPage: (state, action) => {
            state.feedPage = action.payload;
        },
        setFeedSearch: (state, action) => {
            state.feedSearch = action.payload;
        },
        setFeedType: (state, action) => {
            state.feedType = action.payload;
        },
        setFeedChanel: (state, action) => {
            state.feedChanel = action.payload;
        },
        setFeetModalOpen: (state, action) => {
            state.feedModal = action.payload;
        },
    },
});
_a = exports.echoState.actions, exports.setWeather = _a.setWeather, exports.setNotification = _a.setNotification, exports.setFeedPage = _a.setFeedPage, exports.setFeetModalOpen = _a.setFeetModalOpen, exports.setFeedRes = _a.setFeedRes, exports.setFetchNextPage = _a.setFetchNextPage, exports.setNotificationRead = _a.setNotificationRead, exports.setFeedList = _a.setFeedList, exports.setEndOfFeed = _a.setEndOfFeed, exports.setFeedType = _a.setFeedType, exports.setFeedChanel = _a.setFeedChanel, exports.setFeedSearch = _a.setFeedSearch, exports.setTwitch = _a.setTwitch;
const selectEchoStore = (state) => state.echo;
exports.selectEchoStore = selectEchoStore;
const selectWeather = (state) => state.echo.weather;
exports.selectWeather = selectWeather;
const selectNotification = (state) => state.echo.notification;
exports.selectNotification = selectNotification;
const selectFeedList = (state) => state.echo.feedList;
exports.selectFeedList = selectFeedList;
const selectFeedRes = (state) => state.echo.feedRes;
exports.selectFeedRes = selectFeedRes;
const selectEnfOfFeed = (state) => state.echo.endOfFeed;
exports.selectEnfOfFeed = selectEnfOfFeed;
const selectFeedModal = (state) => state.echo.feedModal;
exports.selectFeedModal = selectFeedModal;
const selectFeedPage = (state) => state.echo.feedPage;
exports.selectFeedPage = selectFeedPage;
const selectTwitch = (state) => state.echo.twitch;
exports.selectTwitch = selectTwitch;
const selectFeedChanel = (state) => state.echo.feedChanel;
exports.selectFeedChanel = selectFeedChanel;
const selectFeedType = (state) => state.echo.feedType;
exports.selectFeedType = selectFeedType;
const selectFeedSearch = (state) => state.echo.feedSearch;
exports.selectFeedSearch = selectFeedSearch;
const selectFetchNextPage = (state) => state.echo.fetchNextPage;
exports.selectFetchNextPage = selectFetchNextPage;
