"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lib_1 = require("@/lib");
const ModAction_1 = require("../ModAction");
const mod = {
    name: 'search',
    description: 'Search',
    icon: 'IOSearch',
    menu: 'top',
    panel: lib_1.PanelViews.SEARCH,
    action: ModAction_1.AddPanelAction,
};
exports.default = mod;
