"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_page_essentials_1 = require("@2spb/react-page-essentials");
const ReaderIconCarosell = function (props) {
    const { list } = props;
    const t = (0, react_page_essentials_1.useTranslation)();
    const [cur, setCur] = (0, react_1.useState)(0);
    const [zoom, setZoom] = (0, react_1.useState)(false);
    const scrollHead = () => {
        document.getElementById('reader-info')?.scrollTo(0, 0);
        document.getElementById('reader-info')?.scroll(0, 0);
    };
    const updateCur = (val) => {
        setCur(val);
        scrollHead();
    };
    const keyboard = (event) => {
        switch (event.key) {
            case 'ArrowLeft':
                if (cur > 0) {
                    updateCur(cur - 1);
                }
                break;
            case 'ArrowRight':
                if (cur + 1 < list.length) {
                    updateCur(cur + 1);
                }
                break;
            default:
                break;
        }
    };
    (0, react_1.useEffect)(() => {
        document.addEventListener('keydown', keyboard);
        return () => {
            document.removeEventListener('keydown', keyboard);
        };
    });
    return (react_1.default.createElement("div", { className: "reader-icon-carousel" },
        react_1.default.createElement("div", { className: `reader-icon-carousel--img-wrapper${zoom ? ' reader-icon-carousel--img-wrapper-zoom' : ''}` },
            react_1.default.createElement("img", { width: "100%", src: list[cur], alt: "current" })),
        list.length > 1 ? (react_1.default.createElement("div", { className: "reader-icon-carousel--button-wrapper" },
            react_1.default.createElement("button", { disabled: cur < 1, onClick: () => updateCur(cur - 1) }, t.get('modal.reader.last')),
            react_1.default.createElement("button", { onClick: () => {
                    setZoom(!zoom);
                    scrollHead();
                } }, t.get('modal.reader.zoom')),
            react_1.default.createElement("button", { disabled: cur + 1 >= list.length, onClick: () => updateCur(cur + 1) }, t.get('modal.reader.next')))) : null));
};
exports.default = ReaderIconCarosell;
