"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const react_toastify_1 = require("react-toastify");
const store_1 = require("@/store");
const GlobalContext_1 = require("@/context/GlobalContext");
const FullFHandler_1 = require("@/context/FullFHandler");
function SettingsClient() {
    const t = (0, store_1.useAppSelector)(store_1.selectTrans);
    const context = (0, GlobalContext_1.useGlobalContext)();
    const [customTitle, setCustomTitle] = (0, react_1.useState)('');
    const [customPath, setCustomPath] = (0, react_1.useState)('');
    const [customImg, setCustomImg] = (0, react_1.useState)('');
    const [gameStores, , gameStoresReload] = (0, react_components_1.useQData)(async () => {
        return context.getGameStore();
    });
    const [steamDefault] = (0, react_components_1.useQData)(async () => {
        return context.gameConfigGet('STEAM_DEFAULT');
    });
    const [epicDefault] = (0, react_components_1.useQData)(async () => {
        return context.gameConfigGet('EPIC_DEFAULT');
    });
    if (!(0, FullFHandler_1.isFullContext)(context) || !steamDefault || !epicDefault) {
        return null;
    }
    return (react_1.default.createElement(react_components_1.PortalStepper, { offset: 100, className: "glx-layout-block-large glx-mt-12 glx-h-full-12", conf: [
            {
                key: 'game_tab',
                name: t.get('page.settings.title.store'),
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true, hCenter: true, vCenter: true, flexC: true, gap: 8 },
                    react_1.default.createElement(react_components_1.Table, { rowData: gameStores, columnDefs: [
                            {
                                headerName: t.get('page.settings.form.header.id'),
                                dataType: 'string',
                                field: 'e_id',
                            },
                            {
                                headerName: t.get('page.settings.form.header.type'),
                                dataType: 'string',
                                field: 'type',
                            },
                        ] }))),
            },
            {
                key: 'steam',
                name: 'Steam',
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true, hCenter: true, vCenter: true, flexC: true, gap: 8 },
                    react_1.default.createElement("p", null, t.get('page.settings.game.info.wishlist')),
                    react_1.default.createElement(react_components_1.Grid, { className: "glx-min-w-400 glx-max-w-800", gap: 12, flex: true, flexR: true, flexMobile: true },
                        react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true },
                            react_1.default.createElement(react_components_1.Form, { options: [
                                    [
                                        {
                                            key: 'api_key',
                                            type: react_components_1.InputOptionType.TEXT,
                                            label: t.get('page.settings.form.header.api.key'),
                                            required: true,
                                        },
                                    ],
                                    [
                                        {
                                            key: 'username',
                                            type: react_components_1.InputOptionType.TEXT,
                                            label: t.get('page.settings.form.header.username'),
                                            required: true,
                                        },
                                    ],
                                ], submit: {
                                    buttonText: t.get('global.button.add'),
                                    loading: true,
                                    buttonCenter: true,
                                    onSubmit: async (event) => {
                                        if (event.validateRequired(true)) {
                                            return;
                                        }
                                        const res = await context.registerGameStore({
                                            type: 'STEAM',
                                            apiKey: event.form.api_key,
                                            meta: {
                                                username: event.form.username,
                                            },
                                        });
                                        if (res) {
                                            react_toastify_1.toast.success(t.get('global.message.success'));
                                            gameStoresReload();
                                            event.clear();
                                        }
                                        else {
                                            react_toastify_1.toast.error(t.get('global.message.error'));
                                        }
                                    },
                                } })),
                        react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true },
                            react_1.default.createElement(react_components_1.Form, { compact: true, options: [
                                    [
                                        {
                                            key: 'steam_path',
                                            type: react_components_1.InputOptionType.TEXT,
                                            label: t.get('page.settings.games.steam.path'),
                                            disabled: true,
                                        },
                                    ],
                                    [
                                        {
                                            key: 'button',
                                            type: react_components_1.InputOptionType.CUSTOM,
                                            customElement: {
                                                init: false,
                                                render: (key, form, updateForm, items) => (react_1.default.createElement(react_components_1.Button, { onClick: async () => {
                                                        const path = await context.resolveGameFolderPath();
                                                        if (!path) {
                                                            react_toastify_1.toast.warn(t.get('page.settings.games.error.path'));
                                                            return;
                                                        }
                                                        await context.gameConfigSet('STEAM_DEFAULT', path);
                                                        updateForm('steam_path', path);
                                                    } }, t.get('global.button.change'))),
                                            },
                                        },
                                    ],
                                ], defaultState: {
                                    steam_path: steamDefault,
                                } }),
                            react_1.default.createElement(react_components_1.Button, { onClick: () => {
                                    context.openExternalConfig({
                                        url: 'https://steamcommunity.com/dev/apikey',
                                        external: true,
                                    });
                                } }, t.get('page.settings.game.get.api.key')),
                            react_1.default.createElement("p", { className: "glx-text-center" }, "Domainname: echo4.eu"))))),
            },
            {
                key: 'epic',
                name: 'Epic Games',
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true, hCenter: true, vCenter: true, flexC: true, gap: 8 },
                    react_1.default.createElement(react_components_1.Grid, null,
                        react_1.default.createElement(react_components_1.Form, { compact: true, options: [
                                [
                                    {
                                        key: 'epic_path',
                                        type: react_components_1.InputOptionType.TEXT,
                                        label: t.get('page.settings.games.epic.path'),
                                        disabled: true,
                                    },
                                ],
                                [
                                    {
                                        key: 'button',
                                        type: react_components_1.InputOptionType.CUSTOM,
                                        customElement: {
                                            init: false,
                                            render: (key, form, updateForm) => (react_1.default.createElement(react_components_1.Button, { onClick: async () => {
                                                    const path = await context.resolveGameFolderPath();
                                                    if (!path) {
                                                        react_toastify_1.toast.warn(t.get('page.settings.games.error.path'));
                                                        return;
                                                    }
                                                    await context.gameConfigSet('EPIC_DEFAULT', path);
                                                    updateForm('epic_path', path);
                                                } }, t.get('global.button.change'))),
                                        },
                                    },
                                ],
                            ], defaultState: {
                                epic_path: epicDefault,
                            } })))),
            },
            {
                key: 'action',
                name: t.get('page.settings.games.action'),
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true, hCenter: true, vCenter: true, flexC: true, gap: 8 },
                    react_1.default.createElement(react_components_1.Button, { onClick: async () => {
                            react_toastify_1.toast.info(t.get(`page.games.reload.start`));
                            await context.reloadGames();
                            react_toastify_1.toast.success(t.get(`page.games.reload.done`));
                        } }, t.get(`page.games.reload`)))),
            },
            {
                key: 'tabs',
                name: t.get('page.settings.games.tabs'),
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true, vCenter: true, flexC: true, gap: 8 },
                    react_1.default.createElement(react_components_1.Form, { className: "glx-min-w-400", defaultState: {
                            steam: react_components_1.LocalStorage.flagLoad('launcher-steam'),
                            epic: react_components_1.LocalStorage.flagLoad('launcher-epic'),
                        }, onChange: ({ form }) => {
                            react_components_1.LocalStorage.flagSave('launcher-steam', form.steam || false);
                            react_components_1.LocalStorage.flagSave('launcher-epic', form.epic || false);
                        }, options: [
                            [
                                {
                                    key: 'steam',
                                    type: react_components_1.InputOptionType.CHECKBOX,
                                    label: t.get('page.settings.launcher.steam'),
                                },
                                {
                                    key: 'epic',
                                    type: react_components_1.InputOptionType.CHECKBOX,
                                    label: t.get('page.settings.launcher.epic'),
                                },
                            ],
                        ] }))),
            },
            {
                key: 'custom',
                name: t.get('page.settings.title.custom'),
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true, hCenter: true, flexR: true, gap: 24 },
                    react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true, gap: 8 },
                        react_1.default.createElement("div", null, t.get('page.settings.form.header.preview')),
                        react_1.default.createElement("div", { style: {
                                height: 260,
                                width: 180,
                                backgroundColor: '#000',
                            } }, customImg !== '' && (react_1.default.createElement("img", { loading: "lazy", alt: "preview", src: `data:image/png;base64, ${customImg}`, width: 180, height: 260 })))),
                    react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true, className: "glx-min-w-400 glx-max-w-800", gap: 12 },
                        react_1.default.createElement(react_components_1.Form, { defaultState: {
                                title: customTitle,
                                path: customPath,
                            }, onChange: (f) => setCustomTitle(f.form.title), options: [
                                [
                                    {
                                        key: 'title',
                                        type: react_components_1.InputOptionType.TEXT,
                                        label: t.get('page.settings.form.header.title'),
                                        value: customTitle,
                                        required: true,
                                    },
                                ],
                            ] }),
                        react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true, gap: 12 },
                            react_1.default.createElement("div", null,
                                customPath || t.get('page.settings.games.path.hint'),
                                ' ')),
                        react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, gap: 8 },
                            react_1.default.createElement(react_components_1.Button, { onClick: async () => {
                                    const path = await context.resolveGamePath();
                                    if (!path) {
                                        react_toastify_1.toast.warn(t.get('page.settings.games.error.path'));
                                        return;
                                    }
                                    setCustomPath(path);
                                } }, t.get('page.settings.games.button.path')),
                            react_1.default.createElement(react_components_1.Button, { onClick: async () => {
                                    const path = await context.resolveGameCover();
                                    if (!path) {
                                        react_toastify_1.toast.warn(t.get('page.settings.games.error.img'));
                                        return;
                                    }
                                    setCustomImg(path);
                                } }, t.get('page.settings.games.button.img'))),
                        react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, gap: 8 },
                            react_1.default.createElement(react_components_1.Button, { disabled: customPath === '' || customTitle === '', onClick: async () => {
                                    const creadted = await context.addCustomGame(customTitle, customPath, customImg !== '' ? customImg : undefined);
                                    if (!creadted) {
                                        react_toastify_1.toast.error(t.get('page.settings.games.error.crete'));
                                        return;
                                    }
                                    react_toastify_1.toast.success(t.get('page.settings.games.success.crete'));
                                    setCustomImg('');
                                    setCustomPath('');
                                    setCustomTitle('');
                                } }, t.get('page.settings.games.button.create')))))),
            },
        ] }));
}
exports.default = SettingsClient;
