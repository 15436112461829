"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_toastify_1 = require("react-toastify");
const react_components_1 = require("@grandlinex/react-components");
const store_1 = require("@/store");
const GlobalContext_1 = require("@/context/GlobalContext");
const allConf = {
    required: true,
    submitOnEnter: true,
};
const Wizard = function () {
    const dispatch = (0, store_1.useAppDispatch)();
    const t = (0, store_1.useAppSelector)(store_1.selectTrans);
    const [step, setStep] = (0, react_1.useState)(0);
    const context = (0, GlobalContext_1.useGlobalContext)();
    const steps = [
        react_1.default.createElement("div", { key: "wizard-1", className: "item" },
            react_1.default.createElement(react_components_1.Form, { options: [
                    [
                        {
                            key: 'masterPw',
                            label: t.get(`page.wizard.label.password`),
                            type: react_components_1.InputOptionType.PASSWORD,
                            ...allConf,
                        },
                    ],
                    [
                        {
                            key: 'masterPwAlt',
                            label: t.get(`page.wizard.label.password.repeat`),
                            type: react_components_1.InputOptionType.PASSWORD,
                            ...allConf,
                        },
                    ],
                ], submit: {
                    onSubmit: async ({ form, setError, clear }) => {
                        const { masterPw, masterPwAlt } = form;
                        if (masterPw !== masterPwAlt) {
                            clear();
                            setError({ global: [t.get('page.wizard.password.not.match')] });
                        }
                        else {
                            const res = await context.initNewUser(masterPw);
                            if (!res || res.error) {
                                clear();
                                setError({ global: ['unkown error'] });
                            }
                            else {
                                react_toastify_1.toast.success(t.get('page.wizard.message.success.master'));
                                setStep(1);
                            }
                        }
                    },
                    loading: true,
                    buttonCenter: true,
                    buttonText: t.get(`page.wizard.${step}.button`),
                } })),
        react_1.default.createElement("div", { key: "wizard-2", className: "item" },
            react_1.default.createElement(react_components_1.Form, { options: [
                    [
                        {
                            key: 'username',
                            label: t.get(`page.wizard.label.username`),
                            type: react_components_1.InputOptionType.TEXT,
                            ...allConf,
                        },
                    ],
                    [
                        {
                            key: 'password',
                            label: t.get(`page.wizard.label.password.repeat`),
                            type: react_components_1.InputOptionType.PASSWORD,
                            ...allConf,
                        },
                    ],
                ], submit: {
                    onSubmit: async ({ form, setError }) => {
                        const { username, password } = form;
                        const api = await context.newApiUser(username, password);
                        if (api.success) {
                            react_toastify_1.toast.success(t.get('page.wizard.message.success.api'));
                            setStep(2);
                        }
                        else {
                            setError({ global: [api.message || 'ERROR'] });
                        }
                    },
                    loading: true,
                    buttonCenter: true,
                    buttonText: t.get(`page.wizard.${step}.button`),
                } })),
        react_1.default.createElement("div", { key: "wizard-3", className: "item" },
            react_1.default.createElement(react_components_1.Button, { cancel: true, onClick: () => {
                    dispatch((0, store_1.setInit)(false));
                }, text: t.get(`page.wizard.${step}.button`) })),
    ];
    return (react_1.default.createElement("div", { className: "row wizard" },
        react_1.default.createElement("div", { className: "item" },
            react_1.default.createElement("h2", null, t.get(`page.wizard.header.title`))),
        react_1.default.createElement("div", { className: "item" }, t.get(`page.wizard.${step}.description`)),
        steps[step]));
};
exports.default = Wizard;
