"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkCookie = checkCookie;
exports.useCookie = useCookie;
const react_1 = require("react");
const react_components_1 = require("@grandlinex/react-components");
const store_1 = require("@/store");
const ParseJWT_1 = require("@/lib/ParseJWT");
const GlobalContext_1 = require("@/context/GlobalContext");
const WebFHandler_1 = require("@/context/WebFHandler");
function cookieString(cname, cvalue, expire, domain) {
    const expires = `expires=${expire.toUTCString()}`;
    return `${cname}=${cvalue};${expires};SameSite=Lax;${domain ? `Domain=${domain};` : ''}path=/`;
}
function setCookie(cname, cvalue, expire) {
    const dom = (0, react_components_1.getDocumentMeta)('REACT_C_NAME');
    if (!dom) {
        console.error('No domain found for cookie');
        return;
    }
    document.cookie = cookieString(cname, cvalue, expire, dom);
}
function rmCookie(cname) {
    document.cookie = `${cname}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
}
function clearCookie() {
    rmCookie('glxauth');
}
function getCookie(cname) {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (const element of ca) {
        let c = element;
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
function checkCookie(cname) {
    const user = getCookie(cname);
    return !!user && user !== '';
}
function useCookie() {
    const token = (0, store_1.useAppSelector)(store_1.selectToken);
    const api = (0, store_1.useAppSelector)(store_1.selectEndpoint);
    const context = (0, GlobalContext_1.useGlobalContext)();
    const [valid, setValid] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (token && api && (0, WebFHandler_1.isWebContext)(context)) {
            const jwt = (0, ParseJWT_1.parseJwtLegacy)(token);
            const date = new Date(0);
            date.setUTCSeconds(jwt?.exp || 0);
            setCookie('glxauth', token.replace('Bearer ', ''), date);
            if (checkCookie('glxauth')) {
                setValid(true);
                // toast.success('Cookie set', { autoClose: 2000 });
            }
            else {
                setValid(false);
                console.warn('Cookie invalid domain');
                //        toast.error('Cookie invalid domain', { autoClose: 2000 });
            }
        }
        else {
            setValid(false);
            clearCookie();
            //      toast.warning('Cookie unset', { autoClose: 2000 });
        }
    }, [token, api]);
    return valid;
}
