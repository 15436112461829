"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lib_1 = require("@/lib");
const ModAction_1 = require("../ModAction");
const mod = {
    name: 'editor',
    description: 'Editor',
    icon: 'IOCreateOutline',
    view: lib_1.TabView.EDITOR,
    menu: 'top',
    restriction: {
        feature: lib_1.AppFeature.EDITOR,
    },
    action: ModAction_1.AddTabAction,
    cmd: {
        action: ModAction_1.BaseCommand,
    },
};
exports.default = mod;
