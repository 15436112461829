"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const mod = {
    name: 'logout',
    description: 'Logout',
    icon: 'IOLogOut',
    menu: 'bot',
    action: (dispatch, t, data, context) => {
        context.logOut();
    },
    restriction: {
        context: 'web',
    },
};
exports.default = mod;
