"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUrlFromDrag = getUrlFromDrag;
// eslint-disable-next-line import/prefer-default-export
function getUrlFromDrag(e) {
    const chromeUrl = e.dataTransfer.getData('URL');
    let linkUrl = null;
    let title = null;
    // CHROMIUM
    if (chromeUrl && chromeUrl !== '') {
        linkUrl = chromeUrl;
    }
    // MOZ
    if (!linkUrl) {
        const moz = e.dataTransfer.getData('text/x-moz-url');
        if (moz && moz !== '') {
            const split = moz.split('\n');
            if (split.length === 2) {
                [linkUrl, title] = split;
            }
        }
    }
    return linkUrl
        ? {
            url: linkUrl,
            title,
        }
        : null;
}
