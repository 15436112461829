"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTranslation = exports.useAppSelector = exports.useAppDispatch = void 0;
exports.useTabData = useTabData;
exports.useTabDataRead = useTabDataRead;
exports.useTabQData = useTabQData;
exports.useTableStoreHook = useTableStoreHook;
exports.useIsAdmin = useIsAdmin;
const react_redux_1 = require("react-redux");
const react_components_1 = require("@grandlinex/react-components");
const react_1 = require("react");
const react_dom_1 = require("react-dom");
const react_page_essentials_1 = require("@2spb/react-page-essentials");
Object.defineProperty(exports, "useTranslation", { enumerable: true, get: function () { return react_page_essentials_1.useTranslation; } });
const TabStore_1 = require("@/store/TabStore");
// Use throughout your app instead of plain `useDispatch` and `useSelector`
const useAppDispatch = () => (0, react_redux_1.useDispatch)();
exports.useAppDispatch = useAppDispatch;
exports.useAppSelector = react_redux_1.useSelector;
function useTabData(key, init) {
    const fC = (0, react_components_1.useTabContext)();
    const tab = (0, exports.useAppSelector)((state) => (0, TabStore_1.selectCurrentTab)(state, fC.mode));
    const dispatch = (0, exports.useAppDispatch)();
    const update = (dat) => {
        dispatch((0, TabStore_1.updateCurrentTabData)({
            key,
            pos: fC.mode,
            data: dat ?? null,
        }));
    };
    if (!tab) {
        return [null, update];
    }
    if (tab.data === undefined || tab.data[key] === undefined) {
        update(init ?? null);
    }
    return [(tab.data?.[key] ?? null), update];
}
function useTabDataRead(key) {
    const fC = (0, react_components_1.useTabContext)();
    const tab = (0, exports.useAppSelector)((state) => (0, TabStore_1.selectCurrentTab)(state, fC.mode));
    return (tab.data?.[key] ?? null);
}
function useTabQData(key, q) {
    const [element, setElement] = useTabData(key);
    async function refresh() {
        try {
            const res = await q();
            setElement(res);
        }
        catch (e) {
            console.error(e);
            setElement(null);
        }
    }
    (0, react_1.useEffect)(() => {
        if (element === null) {
            refresh();
        }
    });
    if (element instanceof Error) {
        console.error(element);
        return [null, setElement, refresh];
    }
    return [element, setElement, refresh];
}
function useTableStoreHook(key, init) {
    const [candidates, setCandidates] = useTabData(key, init);
    const [remove, setRemove] = (0, react_1.useState)(null);
    const [updateItem, setUpdate] = (0, react_1.useState)(null);
    const [oldVal, setOldVal] = useTabData(`${key}_next`, 0);
    const [nextVal, setNextVal] = (0, react_1.useState)(oldVal || 0);
    (0, react_1.useEffect)(() => {
        // console.log([remove, candidates, updateItem]);
        if (remove !== null) {
            const res = candidates?.filter((x) => {
                if (Array.isArray(remove)) {
                    return !remove.includes(x.id);
                }
                return x.id !== remove;
            });
            if (res) {
                (0, react_dom_1.flushSync)(() => {
                    setCandidates([...res]);
                });
            }
            else {
                (0, react_dom_1.flushSync)(() => {
                    setCandidates([]);
                });
            }
            (0, react_dom_1.flushSync)(() => {
                setRemove(null);
            });
        }
        if (updateItem) {
            const next = [...(candidates || [])];
            const x = next.findIndex((e) => e.id === updateItem.id);
            if (x >= 0) {
                next[x] = updateItem;
                (0, react_dom_1.flushSync)(() => {
                    setCandidates(next);
                });
            }
            (0, react_dom_1.flushSync)(() => {
                setUpdate(null);
            });
        }
    }, [remove, candidates, updateItem]);
    function push(dat) {
        setCandidates([
            ...(candidates || []),
            {
                id: nextVal,
                el: dat,
            },
        ]);
        setOldVal(nextVal + 1);
        setNextVal(nextVal + 1);
    }
    function update(id, dat) {
        setUpdate({ id, el: dat });
    }
    return { candidates, setCandidates, setRemove, push, update };
}
function useIsAdmin() {
    const permission = (0, exports.useAppSelector)(react_page_essentials_1.selectPermission);
    return (0, react_1.useMemo)(() => permission.includes('admin'), [permission]);
}
