"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const FeedUtil_1 = require("@/utils/FeedUtil");
function TextBlock({ text, wordMap, highlight, }) {
    const part = (0, react_1.useMemo)(() => {
        const split = text.split(' ').filter((c) => {
            return c.trim() !== '';
        });
        const partition = [];
        split.forEach((curVal, index) => {
            const value = curVal.trim();
            const lowVal = value.toLowerCase();
            if (highlight && wordMap.pos.has(lowVal) && wordMap.neg.has(lowVal)) {
                partition.push(react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("span", { style: {
                            textDecorationColor: 'yellow',
                        }, className: "glx-underline" }, (0, FeedUtil_1.replaceHtmlEntities)(value)),
                    ' '));
            }
            else if (highlight && wordMap.pos.has(lowVal)) {
                partition.push(react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("span", { style: {
                            textDecorationColor: 'green',
                        }, className: "glx-underline" }, (0, FeedUtil_1.replaceHtmlEntities)(value)),
                    ' '));
            }
            else if (highlight && wordMap.neg.has(lowVal)) {
                partition.push(react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("span", { style: {
                            textDecorationColor: 'red',
                        }, className: "glx-underline" }, (0, FeedUtil_1.replaceHtmlEntities)(value)),
                    ' '));
            }
            else if (highlight && wordMap.key.has(lowVal)) {
                partition.push(react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("span", { style: {
                            textDecorationStyle: 'dotted',
                        }, className: "glx-underline" }, (0, FeedUtil_1.replaceHtmlEntities)(value)),
                    ' '));
            }
            else {
                partition.push(`${(0, FeedUtil_1.replaceHtmlEntities)(value)} `);
            }
        });
        return partition;
    }, [text, highlight, wordMap]);
    return (react_1.default.createElement("div", { className: "reader-text--block" },
        react_1.default.createElement("div", null, part)));
}
exports.default = TextBlock;
