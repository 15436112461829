"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const FallbackTrans = {
    data: [
        { key: 'page.login.header.title', value: 'Login' },
        { key: 'page.login.label.email', value: 'E-Mail' },
        { key: 'page.login.label.password', value: 'Password' },
        { key: 'page.login.button', value: 'Login' },
    ],
    code: 'en',
    label: 'english',
};
exports.default = FallbackTrans;
