"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const store_1 = require("@/store");
const GlobalContext_1 = require("@/context/GlobalContext");
function convertBytes(num) {
    return `${(num / 1024 / 1024 / 1024).toFixed(1)}`;
}
const SysBlock = function (props) {
    const connected = (0, store_1.useAppSelector)(store_1.selectConnected);
    const context = (0, GlobalContext_1.useGlobalContext)();
    const [data, , reload] = (0, react_components_1.useQData)(async () => {
        return context.sysInfo();
    });
    (0, react_1.useEffect)(() => {
        const int = setInterval(() => {
            reload();
        }, 4000);
        return () => clearInterval(int);
    });
    if (!connected ||
        !data ||
        !data.cpuInfo ||
        !data.cpuTemp ||
        !data.system ||
        !data.memLoad) {
        return null;
    }
    const last = data.cpuLoad[data.cpuLoad.length - 1];
    const lastTemp = data.cpuTemp[data.cpuTemp.length - 1];
    const mem = Math.trunc((data.memLoad.used / data.memLoad.total) * 100);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_components_1.Card, { title: "General", icon: "IOSpeedometer", className: "glx-card--item--fix" },
            react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement("br", null),
                react_1.default.createElement("div", null,
                    data.cpuInfo.manufacturer,
                    " ",
                    data.cpuInfo.brand,
                    " [",
                    data.cpuInfo.cores,
                    " Cores]"),
                react_1.default.createElement(react_components_1.UsageMap, { value: data.cpuLoad.map((x) => x.currentLoad), label: `Load: ${last.currentLoad.toFixed(2)}% [S:${last.currentLoadSystem.toFixed(2)}%,U:${last.currentLoadUser.toFixed(2)}%]` }),
                react_1.default.createElement("br", null),
                data.cpuTemp && lastTemp.main ? (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(react_components_1.UsageMap, { value: data.cpuTemp.map((x) => x.main || 0), label: `Temp: ${lastTemp.main.toFixed(2)}% [MAX: ${lastTemp.max}]` }),
                    react_1.default.createElement("br", null))) : null,
                react_1.default.createElement(react_components_1.Usage, { value: mem, label: `Memory: ${mem}% [${convertBytes(data.memLoad.used)}/${convertBytes(data.memLoad.total)}GB]` }),
                react_1.default.createElement("br", null),
                data.battery && data.battery.hasBattery ? (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(react_components_1.Usage, { value: data.battery.percent, label: `Battery: ${data.battery.percent.toFixed(2)}% [Charging: ${data.battery.isCharging}]` }),
                    react_1.default.createElement("br", null))) : null,
                data.graphics && data.graphics.controllers.length > 0 ? (react_1.default.createElement(react_1.default.Fragment, null, data.graphics.controllers.map((c) => (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("div", null,
                        c.model,
                        " "),
                    react_1.default.createElement(react_components_1.Usage, { value: c.temperatureGpu || 0, label: `Temp: ${c.temperatureGpu || 0}°C ${c.driverVersion ? ` [Driver: ${c.driverVersion}]` : ''}` }),
                    react_1.default.createElement("br", null)))))) : null)),
        react_1.default.createElement(react_components_1.Card, { title: "Device", icon: "IOHardwareChip", className: "glx-card--item--fix" },
            react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement("div", null, data.system.manufacturer),
                react_1.default.createElement("div", null, data.system.model),
                react_1.default.createElement("br", null),
                data.drives.map((drive) => {
                    return (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, flexSpaceB: true },
                            react_1.default.createElement("div", null, drive.mount),
                            react_1.default.createElement("div", null,
                                "(",
                                drive.type,
                                ")")),
                        react_1.default.createElement(react_components_1.Usage, { value: drive.use, label: `${drive.use}% [${convertBytes(drive.used)}/${convertBytes(drive.size)}GB]` }),
                        react_1.default.createElement("br", null)));
                })))));
};
exports.default = SysBlock;
