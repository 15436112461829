"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = useNotificationSettings;
const react_1 = require("react");
const react_page_essentials_1 = require("@2spb/react-page-essentials");
const store_1 = require("@/store");
const FeedUtil_1 = require("@/utils/FeedUtil");
function useNotificationSettings() {
    const feeds = (0, store_1.useAppSelector)(store_1.selectFeedRes);
    const auth = (0, react_page_essentials_1.useAuthHelper)();
    return (0, react_1.useCallback)((el) => {
        let icon = null;
        switch (el.data?.type) {
            case 'YOUTUBE':
                icon = 'IOLogoYoutube';
                break;
            case 'rss-podcast':
                icon = 'IOMic';
                break;
            default:
                icon = null;
        }
        if (el.type === 3) {
            icon = 'IOLogoTwitch';
        }
        let image = el.data?.image;
        if (!image && el.data?.feed) {
            const feed = feeds?.find((x) => x.title === el.title);
            if (feed && feed.default_image) {
                image = auth(`/s/feed-service/image/feed/${feed.e_id}`, true);
            }
        }
        const fallbackIcon = image
            ? undefined
            : {
                icon: icon || 'IONotifications',
                color: 'gray',
            };
        const elProps = {
            key: el.e_id,
            active: !el.user_read,
            title: el.title,
            message: el.message,
            date: (0, FeedUtil_1.parseDate)(el.created),
            selected: false,
        };
        return {
            elProps,
            icon,
            image,
            fallbackIcon,
        };
    }, [feeds]);
}
