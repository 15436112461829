"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lib_1 = require("@/lib");
const ModAction_1 = require("../ModAction");
const mod = {
    name: 'notification',
    description: 'Notification',
    icon: 'IONotifications',
    menu: 'top',
    panel: lib_1.PanelViews.NOTIFICATION,
    action: ModAction_1.AddPanelAction,
    labelNum: {
        notification: (t, data) => {
            const not = data.notification?.filter((n) => !n.user_read);
            const on = data.twitch?.filter((n) => n.online);
            const out = [];
            if (not && not.length > 0) {
                out.push({
                    value: not.length,
                    pos: 'right',
                });
            }
            if (on && on.length > 0) {
                out.push({
                    value: on.length,
                    pos: 'left',
                    color: '#6441a5',
                });
            }
            return out.length > 0 ? out : undefined;
        },
    },
};
exports.default = mod;
