"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lib_1 = require("@/lib");
const ModAction_1 = require("../ModAction");
const mod = {
    name: 'tasks',
    description: 'Sentiment',
    icon: 'IOFlag',
    view: lib_1.TabView.TASK_OVERVIEW,
    menu: 'top',
    action: ModAction_1.AddTabAction,
    cmd: {
        action: ModAction_1.BaseCommand,
    },
    disabled: (t, data) => !data.org || data.org.length === 0,
};
exports.default = mod;
