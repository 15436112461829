"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const store_1 = require("@/store");
const GlobalContext_1 = require("@/context/GlobalContext");
const component_1 = require("@/component");
function SettingsInfo() {
    const t = (0, store_1.useAppSelector)(store_1.selectTrans);
    const context = (0, GlobalContext_1.useGlobalContext)();
    const isAdmin = (0, store_1.useIsAdmin)();
    const dispatch = (0, store_1.useAppDispatch)();
    const langData = (0, store_1.useAppSelector)(store_1.selectRawTrans);
    const [dark, setDark] = (0, react_1.useState)(!!window?.getDarkMode?.());
    return (react_1.default.createElement(react_components_1.PortalStepper, { offset: 100, className: "glx-layout-block-large glx-mt-12 glx-h-full-12", conf: [
            {
                key: 'base',
                name: t.get('page.settings.part.general'),
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true },
                    react_1.default.createElement("div", null,
                        "App: v",
                        (0, react_components_1.getDocumentMeta)('REACT_APP_VERSION')))),
            },
            {
                key: 'lang',
                name: t.get('page.settings.part.lang'),
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true, hCenter: true },
                    react_1.default.createElement(component_1.GridButton, { disabled: langData?.code === 'de', transKey: "general.lang.de", onClick: () => {
                            context.setLang({ key: 'de' }).then(() => {
                                context.getTranslations().then((tr) => {
                                    if (tr.success && tr.data) {
                                        dispatch((0, store_1.setTranslation)(tr.data));
                                    }
                                    else {
                                        window.location.reload();
                                    }
                                });
                            });
                        } },
                        react_1.default.createElement(component_1.FlagDE, null)),
                    react_1.default.createElement(component_1.GridButton, { disabled: langData?.code === 'en', transKey: "general.lang.gb", onClick: () => {
                            context.setLang({ key: 'en' }).then(() => {
                                context.getTranslations().then((tr) => {
                                    if (tr.success && tr.data) {
                                        dispatch((0, store_1.setTranslation)(tr.data));
                                    }
                                    else {
                                        window.location.reload();
                                    }
                                });
                            });
                        } },
                        react_1.default.createElement(component_1.FlagGB, null)),
                    isAdmin && (react_1.default.createElement(component_1.GridButton, { disabled: langData?.code === 'dev', transKey: "general.lang.dev", onClick: () => {
                            context.setLang({ key: 'dev' }).then(() => {
                                context.getTranslations().then((tr) => {
                                    if (tr.success && tr.data) {
                                        dispatch((0, store_1.setTranslation)(tr.data));
                                    }
                                    else {
                                        window.location.reload();
                                    }
                                });
                            });
                        } },
                        react_1.default.createElement(react_components_1.SIGit, null))))),
            },
            {
                key: 'theme',
                name: t.get('page.settings.part.theme'),
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true, hCenter: true },
                    react_1.default.createElement(component_1.GridButton, { disabled: dark, transKey: "page.settings.theme.dark", onClick: () => {
                            window?.setDarkMode?.(true);
                            setDark(true);
                        } },
                        react_1.default.createElement(react_components_1.IOContrastOutline, null)),
                    react_1.default.createElement(component_1.GridButton
                    /* disabled={!dark} */
                    , { 
                        /* disabled={!dark} */
                        disabled: true, transKey: "page.settings.theme.bright", onClick: () => {
                            window?.setDarkMode?.(false);
                            setDark(false);
                        } },
                        react_1.default.createElement(react_components_1.IOContrast, null)))),
            },
        ] }));
}
exports.default = SettingsInfo;
