"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lib_1 = require("@/lib");
const ModAction_1 = require("../ModAction");
const mod = {
    name: 'download',
    description: 'Download',
    icon: 'IODownload',
    view: lib_1.TabView.DOWNLOAD,
    menu: 'top',
    restriction: {
        permission: 'watch',
        context: 'full',
    },
    action: ModAction_1.AddTabAction,
    cmd: {
        action: ModAction_1.BaseCommand,
    },
};
exports.default = mod;
