"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lib_1 = require("@/lib");
const ModAction_1 = require("../ModAction");
const mod = {
    name: 'game',
    description: 'Game',
    icon: 'IOGameController',
    view: lib_1.TabView.GAMES,
    menu: 'top',
    restriction: {
        feature: lib_1.AppFeature.GAMES,
    },
    action: ModAction_1.AddTabAction,
    cmd: {
        action: ModAction_1.BaseCommand,
    },
};
exports.default = mod;
