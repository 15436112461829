"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const store_1 = require("@/store");
const TabStore_1 = require("@/store/TabStore");
const PlayerStore_1 = require("@/store/PlayerStore");
const lib_1 = require("@/lib");
function SettingsClient() {
    const t = (0, store_1.useAppSelector)(store_1.selectTrans);
    const dispatch = (0, store_1.useAppDispatch)();
    const controls = (0, store_1.useAppSelector)(PlayerStore_1.selectControls);
    const counter = (0, store_1.useAppSelector)(TabStore_1.selectUpdateCounter);
    const saveTab = (0, store_1.useAppSelector)(TabStore_1.selectSaveTab);
    const showTask = (0, store_1.useAppSelector)(TabStore_1.selectShowTask);
    const feature = (0, store_1.useAppSelector)(TabStore_1.selectFeatures);
    const eConf = (0, react_1.useMemo)(() => {
        const conf = [];
        if (feature.includes(lib_1.AppFeature.GAMES)) {
            conf.push([
                {
                    key: 'lastPlayed',
                    type: react_components_1.InputOptionType.CHECKBOX,
                    label: t.get('page.settings.game.last.played'),
                },
                {
                    key: 'randomGame',
                    type: react_components_1.InputOptionType.CHECKBOX,
                    label: t.get('page.settings.game.random'),
                },
            ]);
        }
        if (feature.includes(lib_1.AppFeature.SYS_MONITOR)) {
            conf.push([
                {
                    key: 'sysMonitor',
                    type: react_components_1.InputOptionType.CHECKBOX,
                    label: t.get('page.settings.sys.monitor'),
                },
            ]);
        }
        return conf;
    }, [feature, t]);
    return (react_1.default.createElement(react_components_1.PortalStepper, { offset: 100, className: "glx-layout-block-large glx-mt-12 glx-h-full-12", conf: [
            {
                key: 'general',
                name: t.get('page.settings.title.general'),
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true, vCenter: true, flexC: true, gap: 8 },
                    react_1.default.createElement(react_components_1.Form, { className: "glx-min-w-400", defaultState: {
                            cache: saveTab,
                        }, onChange: ({ form }) => {
                            dispatch((0, TabStore_1.setSaveTab)(form.cache));
                            react_components_1.LocalStorage.flagSave('cache_tab', form.cache);
                        }, options: [
                            [
                                {
                                    key: 'cache',
                                    type: react_components_1.InputOptionType.CHECKBOX,
                                    label: t.get('page.settings.cache.tabs'),
                                },
                            ],
                        ] }))),
            },
            {
                key: 'player',
                name: t.get('page.settings.title.player'),
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true, vCenter: true, flexC: true, gap: 8 },
                    react_1.default.createElement(react_components_1.Form, { className: "glx-min-w-400", defaultState: {
                            native: controls,
                            counter: !!counter,
                            cache: saveTab,
                            task: showTask,
                        }, onChange: ({ form }) => {
                            dispatch((0, PlayerStore_1.setControls)(form.native));
                            react_components_1.LocalStorage.flagSave('player_controls', form.native);
                        }, options: [
                            [
                                {
                                    key: 'native',
                                    type: react_components_1.InputOptionType.CHECKBOX,
                                    label: t.get('page.settings.player.native.toolbar'),
                                },
                            ],
                        ] }))),
            },
            {
                key: 'home',
                name: t.get('page.settings.title.home'),
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true, vCenter: true, flexC: true, gap: 8 },
                    react_1.default.createElement(react_components_1.Form, { className: "glx-min-w-400", defaultState: {
                            counter: !!counter,
                            task: showTask,
                            lastPlayed: react_components_1.LocalStorage.flagLoad('lastPlayed'),
                            randomGame: react_components_1.LocalStorage.flagLoad('randomGame'),
                            sysMonitor: react_components_1.LocalStorage.flagLoad('sysMonitor'),
                        }, onChange: ({ form }) => {
                            dispatch((0, TabStore_1.setUpdateCounter)(form.counter ? 240 : null));
                            dispatch((0, TabStore_1.setShowTask)(form.task));
                            react_components_1.LocalStorage.flagSave('show_task', form.task);
                            react_components_1.LocalStorage.flagSave('lastPlayed', form.lastPlayed || false);
                            react_components_1.LocalStorage.flagSave('randomGame', form.randomGame || false);
                            react_components_1.LocalStorage.flagSave('sysMonitor', form.sysMonitor || false);
                        }, options: [
                            [
                                {
                                    key: 'counter',
                                    type: react_components_1.InputOptionType.CHECKBOX,
                                    label: t.get('page.settings.auto.update.feed'),
                                },
                                {
                                    key: 'task',
                                    type: react_components_1.InputOptionType.CHECKBOX,
                                    label: t.get('page.settings.show.task'),
                                },
                            ],
                            ...eConf,
                        ] }))),
            },
        ] }));
}
exports.default = SettingsClient;
