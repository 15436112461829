"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_toastify_1 = require("react-toastify");
const react_components_1 = require("@grandlinex/react-components");
const HCard_1 = require("@grandlinex/react-components/dist/components/HCard/HCard");
const react_page_essentials_1 = require("@2spb/react-page-essentials");
const GlobalContext_1 = require("@/context/GlobalContext");
const lib_1 = require("@/lib");
function SubPage() {
    const context = (0, GlobalContext_1.useGlobalContext)();
    const auth = (0, react_page_essentials_1.useAuthHelper)();
    const t = (0, react_page_essentials_1.useTranslation)();
    const [search, setSearch] = (0, react_1.useState)('');
    const [list, , reload] = (0, react_components_1.useQData)(async () => {
        const l = await context.feedGetList();
        return l.data;
    });
    const [my, , myReload] = (0, react_components_1.useQData)(async () => {
        const tm = await context.listMyTwitchChannel();
        return tm.data;
    });
    const [all] = (0, react_components_1.useQData)(async () => {
        const ta = await context.listAllTwitchChannel();
        return ta.data;
    });
    const data = (0, react_1.useMemo)(() => {
        if (!list || !my || !all) {
            return null;
        }
        if (search === '') {
            return {
                list,
                all,
            };
        }
        return {
            list: list.filter((el) => el.title.toLowerCase().includes(search.toLowerCase())),
            all: all.filter((el) => el.name.toLowerCase().includes(search.toLowerCase())),
        };
    }, [list, my, all, search]);
    if (!list || !my || !all || !data) {
        return (react_1.default.createElement(react_components_1.PortalStepper, { key: "loading_stepper", offset: 100, collapse: true, className: "glx-w-full-12 glx-mt-12 glx-h-full-12 feed-sub-list glx-layout-block-extra-large", conf: [
                {
                    key: 'general',
                    name: t.get(`page.feedlist.title.general`),
                    render: (react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true },
                        react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, gap: 24 },
                            react_1.default.createElement("div", null,
                                t.get(`page.feedlist.general.channel.count`),
                                ":",
                                ' ',
                                list?.length || 0),
                            react_1.default.createElement("div", null,
                                t.get(`page.feedlist.general.sub.count`),
                                ":",
                                ' ',
                                list?.filter((el) => el.subscribed).length || 0),
                            react_1.default.createElement("div", null,
                                t.get(`page.feedlist.general.notify.count`),
                                ":",
                                ' ',
                                list?.filter((el) => el.notify).length || 0)),
                        react_1.default.createElement(react_components_1.Grid, null,
                            react_1.default.createElement(react_components_1.Form, { options: [
                                    [
                                        {
                                            type: react_components_1.InputOptionType.TEXT,
                                            key: 'search',
                                            label: t.get(`page.feedlist.general.search`),
                                        },
                                    ],
                                ], onChange: ({ form }) => {
                                    setSearch(form.search);
                                } })))),
                },
                {
                    key: 'loading',
                    name: t.get(`page.feedlist.title.loading`),
                    render: (react_1.default.createElement("div", { className: "glx-flex-row glx-flex-wrap glx-flex-center" }, Array.from(new Array(12).keys())?.map((x) => (react_1.default.createElement(HCard_1.HCard, { key: `${x}-skeleton`, iconCover: true, skeleton: true, noIcon: true, imageWidth: "160px" }))))),
                },
            ] }));
    }
    const conf = [
        {
            key: 'general',
            name: t.get(`page.feedlist.title.general`),
            render: (react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true },
                react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, gap: 24 },
                    react_1.default.createElement("div", null,
                        t.get(`page.feedlist.general.channel.count`),
                        ":",
                        ' ',
                        list?.length || 0),
                    react_1.default.createElement("div", null,
                        t.get(`page.feedlist.general.sub.count`),
                        ":",
                        ' ',
                        list?.filter((el) => el.subscribed).length || 0),
                    react_1.default.createElement("div", null,
                        t.get(`page.feedlist.general.notify.count`),
                        ":",
                        ' ',
                        list?.filter((el) => el.notify).length || 0)),
                react_1.default.createElement(react_components_1.Grid, null,
                    react_1.default.createElement(react_components_1.Form, { options: [
                            [
                                {
                                    type: react_components_1.InputOptionType.TEXT,
                                    key: 'search',
                                    label: t.get(`page.feedlist.general.search`),
                                },
                            ],
                        ], onChange: ({ form }) => {
                            setSearch(form.search);
                        } })))),
        },
    ];
    for (const el in lib_1.ResourceTypeEnum) {
        if (el) {
            const cur = data.list?.filter((del) => del.resource_type === el);
            let icon;
            let color;
            switch (el) {
                case lib_1.ResourceTypeEnum.YOUTUBE:
                    icon = 'IOLogoYoutube';
                    color = 'red';
                    break;
                case lib_1.ResourceTypeEnum.PODCAST:
                    icon = 'IOMicOutline';
                    color = 'green';
                    break;
                case lib_1.ResourceTypeEnum.NEWS:
                    icon = 'IONewspaper';
                    color = 'orange';
                    break;
                case lib_1.ResourceTypeEnum.GAMES:
                    icon = 'IOGameController';
                    color = 'black';
                    break;
                default:
                    icon = undefined;
                    color = undefined;
            }
            conf.push({
                key: el,
                name: t.get(`page.feedlist.title.${el.toLowerCase()}`),
                render: (react_1.default.createElement("div", { className: "glx-flex-row glx-flex-wrap glx-flex-center" }, cur
                    ?.toSorted((a, b) => a.title.localeCompare(b.title))
                    .map((del) => (react_1.default.createElement(HCard_1.HCard, { key: `${del.e_id}${del.subscribed ? 'sub' : 'nsub'}`, iconCover: true, icon: icon, noIcon: true, imageWidth: "160px", color: color, title: del.title, hoverButton: !del.subscribed
                        ? [
                            {
                                icon: 'IOCheckmarkCircleOutline',
                                tooltip: {
                                    text: t.get('page.feedlist.subscribe'),
                                },
                                onClick: () => {
                                    context
                                        .feedSubscribe({ id: del.e_id }, 'false')
                                        .then((r) => {
                                        if (r.success) {
                                            react_toastify_1.toast.success(t.get('page.feedlist.sub.message'), {
                                                autoClose: 1300,
                                            });
                                            reload();
                                        }
                                        else {
                                            react_toastify_1.toast.error(t.get('page.feedlist.error'));
                                        }
                                    });
                                },
                            },
                            {
                                icon: 'IOMegaphone',
                                tooltip: {
                                    text: t.get('page.feedlist.subscribe.notify'),
                                },
                                onClick: () => {
                                    context
                                        .feedSubscribe({ id: del.e_id }, 'true')
                                        .then((r) => {
                                        if (r.success) {
                                            react_toastify_1.toast.success(t.get('page.feedlist.sub.message'), {
                                                autoClose: 1300,
                                            });
                                            reload();
                                        }
                                        else {
                                            react_toastify_1.toast.error(t.get('page.feedlist.error'));
                                        }
                                    });
                                },
                            },
                        ]
                        : [
                            {
                                icon: 'IORemoveCircleOutline',
                                tooltip: {
                                    text: t.get('page.feedlist.unsubscribe'),
                                },
                                onClick: () => {
                                    context
                                        .feedUnsubscribe({ id: del.e_id })
                                        .then((r) => {
                                        if (r.success) {
                                            react_toastify_1.toast.success(t.get('page.feedlist.unsub.message'), {
                                                autoClose: 1300,
                                            });
                                            reload();
                                        }
                                        else {
                                            react_toastify_1.toast.error(t.get('page.feedlist.error'));
                                        }
                                    });
                                },
                            },
                        ], imgUrl: del.default_image
                        ? auth(`/s/feed-service/image/feed/${del.e_id}`, true)
                        : undefined },
                    react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, hCenter: true, className: del.subscribed ? 'glx-text-green' : 'glx-text-red' },
                        react_1.default.createElement("span", null, del.subscribed
                            ? t.get('page.feedlist.subscribed')
                            : t.get('page.feedlist.not.subscribed')),
                        del.subscribed && del.notify ? (react_1.default.createElement("span", null,
                            react_1.default.createElement(react_components_1.IOMegaphone, { size: react_components_1.ISize.SL }))) : null)))))),
            });
        }
    }
    conf.push({
        key: 'twitch',
        name: t.get(`page.feedlist.title.twitch`),
        render: (react_1.default.createElement("div", { className: "glx-flex-row glx-flex-wrap glx-flex-center" }, data.all
            ?.toSorted((a, b) => a.name.localeCompare(b.name))
            .map((del) => {
            const cur = my?.find((m) => m.e_id === del.e_id);
            return (react_1.default.createElement(HCard_1.HCard, { key: `${del.e_id}${cur ? 'sub' : 'nsub'}`, imageWidth: "160px", iconCover: true, icon: "IOLogoTwitch", noIcon: true, title: del.name, hoverButton: !cur
                    ? [
                        {
                            icon: 'IOCheckmarkCircleOutline',
                            tooltip: {
                                text: t.get('page.feedlist.subscribe'),
                            },
                            onClick: () => {
                                context
                                    .subTwitchChannel({ id: del.e_id, notify: false })
                                    .then((r) => {
                                    if (r.success) {
                                        react_toastify_1.toast.success(t.get('page.feedlist.sub.message'), {
                                            autoClose: 1300,
                                        });
                                        myReload();
                                    }
                                    else {
                                        react_toastify_1.toast.error(t.get('page.feedlist.error'));
                                    }
                                });
                            },
                        },
                        {
                            icon: 'IOMegaphone',
                            tooltip: {
                                text: t.get('page.feedlist.subscribe.notify'),
                            },
                            onClick: () => {
                                context
                                    .subTwitchChannel({ id: del.e_id, notify: true })
                                    .then((r) => {
                                    if (r.success) {
                                        react_toastify_1.toast.success(t.get('page.feedlist.sub.message'), {
                                            autoClose: 1300,
                                        });
                                        myReload();
                                    }
                                    else {
                                        react_toastify_1.toast.error(t.get('page.feedlist.error'));
                                    }
                                });
                            },
                        },
                    ]
                    : [
                        {
                            icon: 'IORemoveCircleOutline',
                            tooltip: {
                                text: t.get('page.feedlist.unsubscribe'),
                            },
                            onClick: () => {
                                context.dSubTwitchChannel(del.e_id).then((r) => {
                                    if (r.success) {
                                        react_toastify_1.toast.success(t.get('page.feedlist.unsub.message'), {
                                            autoClose: 1300,
                                        });
                                        myReload();
                                    }
                                    else {
                                        react_toastify_1.toast.error(t.get('page.feedlist.error'));
                                    }
                                });
                            },
                        },
                    ] },
                react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, hCenter: true, className: cur ? 'glx-text-green' : 'glx-text-red' },
                    react_1.default.createElement("span", null, cur
                        ? t.get('page.feedlist.subscribed')
                        : t.get('page.feedlist.not.subscribed')),
                    cur ? (react_1.default.createElement("span", null,
                        react_1.default.createElement(react_components_1.IOMegaphone, { size: react_components_1.ISize.SL }))) : null)));
        }))),
    });
    return (react_1.default.createElement(react_components_1.PortalStepper, { key: "feed_stepper", offset: 100, collapse: true, className: "glx-w-full-12 glx-mt-12 glx-h-full-12 feed-sub-list glx-layout-block-extra-large", conf: conf }));
}
exports.default = SubPage;
