"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ModAction_1 = require("../ModAction");
const GameLib_1 = require("@/lib/GameLib");
const lib_1 = require("@/lib");
const mod = {
    name: 'steam',
    description: 'Open Steam',
    icon: 'IOLogoSteam',
    menu: 'top',
    action: ModAction_1.OpenLauncher,
    restriction: {
        flag: 'launcher-steam',
        feature: lib_1.AppFeature.GAMES,
    },
    cmd: {
        action: ModAction_1.BaseCommand,
    },
    meta: {
        launcher: GameLib_1.GameStoreType.STEAM,
    },
};
exports.default = mod;
