"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const store_1 = require("@/store");
const GlobalContext_1 = require("@/context/GlobalContext");
const FullFHandler_1 = require("@/context/FullFHandler");
function SettingsShortcuts() {
    const t = (0, store_1.useAppSelector)(store_1.selectTrans);
    const context = (0, GlobalContext_1.useGlobalContext)();
    const rowData = (0, react_1.useMemo)(() => {
        const base = [
            {
                keys: ['Alt', 'H'],
                description: t.get('page.settings.hotkey.description.home'),
            },
            {
                keys: ['Alt', 'S'],
                description: t.get('page.settings.hotkey.description.settings'),
            },
            {
                keys: ['Alt', 'W'],
                description: t.get('page.settings.hotkey.description.close.tab'),
            },
            {
                keys: ['Alt', 'V'],
                description: t.get('page.settings.hotkey.description.auto.reload'),
            },
            {
                keys: ['Ctrl', 'Space'],
                description: t.get('page.settings.hotkey.description.open.spotlight'),
            },
            {
                keys: ['.'],
                description: t.get('page.settings.hotkey.description.player.back'),
            },
            {
                keys: [','],
                description: t.get('page.settings.hotkey.description.player.forward'),
            },
            {
                keys: ['Alt', 'N'],
                description: t.get('page.settings.hotkey.description.player.last'),
            },
            {
                keys: ['Alt', 'M'],
                description: t.get('page.settings.hotkey.description.player.next'),
            },
        ];
        if ((0, FullFHandler_1.isFullContext)(context)) {
            base.push({
                keys: ['Alt', 'O'],
                description: t.get('page.settings.hotkey.description.overlay'),
            });
        }
        return base;
    }, [t]);
    return (react_1.default.createElement(react_components_1.PortalStepper, { offset: 100, className: "glx-layout-block-large glx-mt-12 glx-h-full-12", conf: [
            {
                key: 'shortcuts',
                name: t.get('page.settings.title.shortcuts'),
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true, vCenter: true, flexC: true, gap: 8 },
                    react_1.default.createElement(react_components_1.Table, { className: "glx-min-w-400 glx-max-w-800", rowData: rowData, columnDefs: [
                            {
                                field: 'keys',
                                headerName: 'Keys',
                                cellRenderer: (dat) => {
                                    return (react_1.default.createElement("div", null, dat.data.keys.map((k, i, a) => (react_1.default.createElement(react_1.default.Fragment, null,
                                        react_1.default.createElement("span", { className: "keyboard-shortcut" }, k),
                                        i + 1 < a.length ? ' + ' : null)))));
                                },
                            },
                            {
                                field: 'description',
                                headerName: 'Description',
                                dataType: 'string',
                            },
                        ] }))),
            },
        ] }));
}
exports.default = SettingsShortcuts;
