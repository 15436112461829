"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const store_1 = require("@/store");
const lib_1 = require("@/lib");
const TabStore_1 = require("@/store/TabStore");
const pageList = [
    ['IOInformation', 'info', lib_1.TabView.ADMIN_INFO],
    ['IONewspaperOutline', 'feeds', lib_1.TabView.ADMIN_FEED],
    ['IONewspaperOutline', 'feeds.strategy', lib_1.TabView.ADMIN_FEED_STRATEGY],
    ['IOLogoTwitch', 'twitch', lib_1.TabView.ADMIN_TWITCH],
];
const AdminPanel = function () {
    const dispatch = (0, store_1.useAppDispatch)();
    const t = (0, store_1.useAppSelector)(store_1.selectTrans);
    const openSubTab = (0, react_1.useCallback)((key, view, icon) => {
        dispatch((0, TabStore_1.addTab)({
            item: {
                key: (0, react_components_1.uuid)(),
                view,
                titel: t.get(`page.admin.panel.${key}`),
                icon,
            },
            pos: 'left',
        }));
    }, [dispatch, t]);
    return (react_1.default.createElement(react_components_1.Grid, { className: "page-grid-wrapper", flex: true, flexC: true, center: true, fullWidth: true, gap: 12 },
        react_1.default.createElement(react_components_1.Grid, null,
            react_1.default.createElement("h2", null, t.get('tab.name.admin'))),
        react_1.default.createElement(react_components_1.Grid, { className: "page-grid", flex: true, flexR: true, hCenter: true, gap: 12 }, pageList.map(([icon, name, view]) => (react_1.default.createElement(react_components_1.Grid, { key: `admin_${name}`, className: "page-grid--element", flex: true, flexR: true, vCenter: true, hCenter: true, gap: 8, onClick: () => openSubTab(name, view, icon) },
            (0, react_components_1.getIcon)(icon)({}),
            " ",
            t.get(`page.admin.panel.${name}`)))))));
};
exports.default = AdminPanel;
