"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = default_1;
const react_components_1 = require("@grandlinex/react-components");
const react_toastify_1 = require("react-toastify");
const react_1 = __importStar(require("react"));
const store_1 = require("@/store");
const FullFHandler_1 = require("@/context/FullFHandler");
const GlobalContext_1 = require("@/context/GlobalContext");
const TabStore_1 = require("@/store/TabStore");
function default_1() {
    const t = (0, store_1.useAppSelector)(store_1.selectTrans);
    const context = (0, GlobalContext_1.useGlobalContext)();
    const dispatch = (0, store_1.useAppDispatch)();
    const [featureList, , reload] = (0, react_components_1.useQData)(async () => {
        return (0, FullFHandler_1.isFullContext)(context) ? context.getAllFeatures() : null;
    });
    const updateFeature = () => {
        context.getFeatures().then((f) => {
            dispatch((0, TabStore_1.setFeatures)(f));
        });
    };
    const opts = (0, react_1.useMemo)(() => {
        const options = [];
        if (featureList) {
            for (const f of featureList) {
                const el = {
                    key: f.name,
                    type: react_components_1.InputOptionType.CHECKBOX,
                    label: (react_1.default.createElement(react_components_1.Grid, { flex: true, flexRow: true, gap: 4, vCenter: true, hCenter: true },
                        (0, react_components_1.getIcon)(f.icon)({}),
                        t.get(`global.feature.${f.name.toLowerCase()}`))),
                    onChange: (v) => {
                        context.setFeatures(f.e_id, v).then(() => {
                            react_toastify_1.toast.dark(`Feature '${f.name}' ${v ? 'enabled' : 'disabled'}`);
                            updateFeature();
                        });
                    },
                };
                if (options.length > 0 && options[options.length - 1]?.length === 1) {
                    options[options.length - 1].push(el);
                }
                else {
                    options.push([el]);
                }
            }
        }
        return [...options];
    }, [featureList, t]);
    const def = (0, react_1.useMemo)(() => {
        const defX = {};
        if (featureList) {
            for (const f of featureList) {
                defX[f.name] = f.enabled;
            }
            return defX;
        }
        return null;
    }, [featureList, t]);
    if (!featureList || !def)
        return null;
    return (react_1.default.createElement("div", { className: "glx-min-w-400" },
        react_1.default.createElement(react_components_1.Form, { compact: true, defaultState: def, options: opts })));
}
