"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.store = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_page_essentials_1 = require("@2spb/react-page-essentials");
const EchoStore_1 = require("@/store/EchoStore");
const PlayerStore_1 = require("@/store/PlayerStore");
const TabStore_1 = require("@/store/TabStore");
// APP STORE
exports.store = (0, toolkit_1.configureStore)({
    reducer: {
        app: react_page_essentials_1.appState.reducer,
        echo: EchoStore_1.echoState.reducer,
        player: PlayerStore_1.playerState.reducer,
        mainframe: TabStore_1.mfState.reducer,
    },
});
