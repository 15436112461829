"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_components_1 = require("@grandlinex/react-components");
const lib_1 = require("@/lib");
const TabStore_1 = require("@/store/TabStore");
const ModAction_1 = require("@/layout/ModAction");
const mod = {
    name: 'element',
    description: 'Element',
    icon: 'SIElement',
    menu: 'top',
    restriction: {
        feature: lib_1.AppFeature.ELEMENT,
    },
    action: (dispatch, t, data) => {
        dispatch((0, TabStore_1.addTab)({
            item: {
                key: (0, react_components_1.uuid)(),
                view: lib_1.TabView.IFRAME,
                icon: data.icon,
                titel: t.get(`tab.name.${data.name}`),
                data: 'https://element.echo4.eu',
            },
        }));
    },
    cmd: {
        action: ModAction_1.BaseCommand,
    },
};
exports.default = mod;
