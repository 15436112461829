"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const TabStore_1 = require("@/store/TabStore");
const mod = {
    name: 'spotlight',
    description: 'Spotlight',
    icon: 'IOFlash',
    menu: 'bot',
    action: (dispatch, t, data, context) => {
        dispatch((0, TabStore_1.setSpotlight)(true));
    },
};
exports.default = mod;
