"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAVMSwitch = isAVMSwitch;
exports.isAVMBulb = isAVMBulb;
function isAVMSwitch(device) {
    const d = device;
    return d.button !== undefined && d.battery !== undefined;
}
function isAVMBulb(device) {
    const d = device;
    return d.simpleonoff !== undefined && d.levelcontrol !== undefined;
}
