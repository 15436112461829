"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = OrgCrumbs;
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const store_1 = require("@/store");
function OrgCrumbs({ items }) {
    const tree = (0, store_1.useAppSelector)(store_1.selectOrg);
    const org = (0, store_1.useTabDataRead)('org');
    const team = (0, store_1.useTabDataRead)('team');
    const project = (0, store_1.useTabDataRead)('project');
    const board = (0, store_1.useTabDataRead)('text');
    const crumbs = (0, react_1.useMemo)(() => {
        const arr = [];
        if (!org || !tree) {
            return null;
        }
        const orgE = tree?.find((x) => x.e_id === org);
        if (orgE) {
            arr.push({
                key: 'org',
                name: orgE.title,
            });
            if (team) {
                const teamE = orgE.team.find((x) => x.e_id === team);
                if (teamE) {
                    arr.push({
                        key: 'team',
                        name: teamE.title,
                    });
                    if (project) {
                        const projectE = teamE.project.find((x) => x.e_id === project);
                        if (projectE) {
                            arr.push({
                                key: 'project',
                                name: projectE.title,
                            });
                        }
                    }
                }
            }
            if (board) {
                arr.push({
                    key: 'board',
                    name: board,
                });
            }
        }
        if (items) {
            arr.push(...items);
        }
        return arr;
    }, [org, tree, items]);
    if (crumbs === null) {
        return crumbs;
    }
    return react_1.default.createElement(react_components_1.Breadcrumbs, { items: crumbs });
}
