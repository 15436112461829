"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFullContext = isFullContext;
const full_con_1 = require("@awesomegroup/full-con");
function isFullContext(dat) {
    return typeof dat === 'object' && dat?.name === 'full';
}
class FullFHandler extends full_con_1.CApiCon {
    name;
    features;
    bus;
    constructor(prop) {
        super();
        this.features = prop.features;
        this.bus = prop.bus;
        this.name = 'full';
    }
    async getFeatures() {
        const ff = [...this.features];
        (await this.getAllFeatures()).forEach((x) => {
            if (x.enabled) {
                ff.push(x.name);
            }
        });
        return ff;
    }
    getName() {
        return this.name;
    }
}
exports.default = FullFHandler;
