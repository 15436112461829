"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = useOrgData;
const react_components_1 = require("@grandlinex/react-components");
const react_1 = require("react");
const TabStore_1 = require("@/store/TabStore");
const store_1 = require("@/store");
const lib_1 = require("@/lib");
function useOrgData() {
    const dispatch = (0, store_1.useAppDispatch)();
    const t = (0, store_1.useAppSelector)(store_1.selectTrans);
    const orgTree = (0, store_1.useAppSelector)(store_1.selectOrg);
    return (0, react_1.useMemo)(() => {
        const option = [];
        const data = orgTree;
        if (!data)
            return null;
        /** ********************************************************************* */
        for (const org of data) {
            const sel = org.e_id;
            const teams = org.team;
            option.push({
                key: `${sel}_dashboard`,
                path: [org.title],
                optionAction: () => {
                    dispatch((0, TabStore_1.addTab)({
                        item: {
                            key: (0, react_components_1.uuid)(),
                            data: {
                                org: sel,
                            },
                            view: lib_1.TabView.ORG_OVERVIEW,
                            icon: 'IOCompass',
                            titel: org.title,
                        },
                    }));
                    dispatch((0, TabStore_1.setSpotlight)(false));
                },
                text: t.get('sidebar.header.org.menu.dashboard'),
                icon: 'IOCompass',
            });
            teams.forEach((team) => {
                option.push({
                    key: `${team.e_id}_team-dashboard`,
                    path: [org.title, team.title],
                    optionAction: () => {
                        dispatch((0, TabStore_1.addTab)({
                            item: {
                                key: (0, react_components_1.uuid)(),
                                data: {
                                    org: org.e_id,
                                },
                                view: lib_1.TabView.TEAM_OVERVIEW,
                                icon: 'IOCompass',
                                titel: team.title,
                            },
                        }));
                        dispatch((0, TabStore_1.setSpotlight)(false));
                    },
                    text: t.get('sidebar.header.org.menu.dashboard'),
                    icon: 'IOCompass',
                });
                team.project.forEach((project) => {
                    option.push({
                        key: `${project.e_id}_project-dashboard`,
                        path: [org.title, team.title, project.title],
                        optionAction: () => {
                            dispatch((0, TabStore_1.addTab)({
                                item: {
                                    key: (0, react_components_1.uuid)(),
                                    data: {
                                        org: sel,
                                        project: project.e_id,
                                    },
                                    view: lib_1.TabView.PROJECT_OVERVIEW,
                                    icon: 'IOCompass',
                                    titel: project.title,
                                },
                            }));
                            dispatch((0, TabStore_1.setSpotlight)(false));
                        },
                        text: t.get('sidebar.header.org.menu.dashboard'),
                        icon: 'IOCompass',
                    });
                    option.push({
                        key: `${project.e_id}_project-table`,
                        path: [org.title, team.title, project.title],
                        optionAction: () => {
                            dispatch((0, TabStore_1.addTab)({
                                item: {
                                    key: (0, react_components_1.uuid)(),
                                    data: {
                                        org: org.e_id,
                                        team: team.e_id,
                                        project: project.e_id,
                                    },
                                    view: lib_1.TabView.PROJECT_ELEMENT_LIST,
                                    titel: project.title,
                                },
                            }));
                            dispatch((0, TabStore_1.setSpotlight)(false));
                        },
                        text: t.get('sidebar.header.org.menu.table'),
                        icon: 'IOPodium',
                    });
                    project.board.forEach((board) => {
                        option.push({
                            key: `${board.board.e_id}_board-canban`,
                            path: [org.title, team.title, project.title, board.board.title],
                            optionAction: () => {
                                dispatch((0, TabStore_1.addTab)({
                                    item: {
                                        key: (0, react_components_1.uuid)(),
                                        data: {
                                            org: org.e_id,
                                            team: team.e_id,
                                            project: project.e_id,
                                            key: board.board.e_id,
                                            text: board.board.title,
                                        },
                                        view: lib_1.TabView.KANBAN_BOARD,
                                        icon: 'IOGrid',
                                        titel: board.board.title,
                                    },
                                }));
                                dispatch((0, TabStore_1.setSpotlight)(false));
                            },
                            text: t.get('sidebar.header.org.menu.board'),
                            icon: 'IOGrid',
                        });
                        option.push({
                            key: `${board.board.e_id}_board-calendar`,
                            path: [org.title, team.title, project.title, board.board.title],
                            optionAction: () => {
                                dispatch((0, TabStore_1.addTab)({
                                    item: {
                                        key: (0, react_components_1.uuid)(),
                                        data: {
                                            org: org.e_id,
                                            team: team.e_id,
                                            project: project.e_id,
                                            key: board.board.e_id,
                                            name: board.board.title,
                                        },
                                        view: lib_1.TabView.KANBAN_BOARD_CALENDAR,
                                        icon: 'IOCalendarNumberOutline',
                                        titel: board.board.title,
                                    },
                                }));
                                dispatch((0, TabStore_1.setSpotlight)(false));
                            },
                            text: t.get('sidebar.header.org.menu.calendar'),
                            icon: 'IOCalendarNumberOutline',
                        });
                        option.push({
                            key: `${board.board.e_id}_board-timeline`,
                            path: [org.title, team.title, project.title, board.board.title],
                            optionAction: () => {
                                dispatch((0, TabStore_1.addTab)({
                                    item: {
                                        key: (0, react_components_1.uuid)(),
                                        data: {
                                            org: org.e_id,
                                            team: team.e_id,
                                            project: project.e_id,
                                            key: board.board.e_id,
                                            name: board.board.title,
                                        },
                                        view: lib_1.TabView.KANBAN_BOARD_TIMELINE,
                                        icon: 'IOSpeedometerOutline',
                                        titel: board.board.title,
                                    },
                                }));
                                dispatch((0, TabStore_1.setSpotlight)(false));
                            },
                            text: t.get('sidebar.header.org.menu.timeline'),
                            icon: 'IOSpeedometerOutline',
                        });
                    });
                    //     level01.children.push(l1);
                });
                // return level01;
            });
        }
        /** ********************************************************************* */
        return option;
    }, [orgTree, t, dispatch]);
}
