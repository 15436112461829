"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const react_page_essentials_1 = require("@2spb/react-page-essentials");
const store_1 = require("@/store");
const PlayerStore_1 = require("@/store/PlayerStore");
function FloatingButton(props) {
    const t = (0, react_page_essentials_1.useTranslation)();
    const { items, icon, nItems } = props;
    const [open, setOpen] = (0, react_1.useState)(false);
    const playlist = (0, store_1.useAppSelector)(PlayerStore_1.selectPlG);
    const mPlayer = playlist && playlist.data && playlist.data.length > 0;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("button", { type: "button", onClick: () => setOpen(!open), className: `floating-button--base glx-flex-column glx-flex-center${mPlayer ? ' floating-button--translate' : ''}` }, open ? react_1.default.createElement(react_components_1.IOCloseOutline, { size: react_components_1.ISize.SM }) : icon),
        react_1.default.createElement("div", { className: `floating-button--menu${mPlayer ? ' floating-menu--translate' : ''}` },
            nItems?.map((el) => (react_1.default.createElement("div", { className: !open ? 'glx-fade-in-fast' : 'glx-fade-out-fast' },
                react_1.default.createElement(react_components_1.Tooltip, { text: el.transKey ? t.get(el.transKey) : el.text || '', position: "left" },
                    react_1.default.createElement("button", { type: "button", onClick: () => {
                            el.onClick?.();
                            setOpen(false);
                        } }, el.icon))))),
            items.map((el) => (react_1.default.createElement("div", { className: open ? 'glx-fade-in-fast' : 'glx-fade-out-fast' },
                react_1.default.createElement(react_components_1.Tooltip, { text: el.transKey ? t.get(el.transKey) : el.text || '', position: "left" },
                    react_1.default.createElement("button", { type: "button", onClick: () => {
                            el.onClick?.();
                            setOpen(false);
                        } }, el.icon))))))));
}
exports.default = FloatingButton;
