"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JWT = void 0;
class JWT {
    token;
    exp;
    iat;
    data;
    constructor(token) {
        if (token.startsWith('Bearer ') || token.startsWith('bearer ')) {
            this.token = token.substring(7);
        }
        else {
            this.token = token;
        }
        this.data = JWT.parseJwt(this.token);
        this.exp = this.data.exp;
        this.iat = this.data.iat;
    }
    static parseJwt(token) {
        const base64Payload = token.split('.')[1];
        const payload = atob(base64Payload);
        return JSON.parse(payload.toString());
    }
    isExpired() {
        return this.exp < Date.now() / 1000;
    }
    getExpireDate() {
        const date = new Date(0);
        date.setUTCSeconds(this.exp);
        return date;
    }
    getData() {
        return this.data;
    }
    getToken() {
        return this.token;
    }
    getHeader() {
        return `Bearer ${this.getToken()}`;
    }
}
exports.JWT = JWT;
