"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lib_1 = require("@/lib");
const ModAction_1 = require("../ModAction");
const mod = {
    name: 'sentiment',
    description: 'Sentiment',
    icon: 'IOReceipt',
    restriction: {
        feature: lib_1.AppFeature.SENTIMENT,
    },
    view: lib_1.TabView.SENTIMENT,
    menu: 'top',
    action: ModAction_1.AddTabAction,
};
exports.default = mod;
