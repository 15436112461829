"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ModAction_1 = require("../ModAction");
const lib_1 = require("@/lib");
const mod = {
    name: 'feed',
    description: 'Feed Subscriptions',
    icon: 'IOLogoRss',
    view: lib_1.TabView.FEED_LIST,
    menu: 'bot',
    action: ModAction_1.AddTabAction,
};
exports.default = mod;
