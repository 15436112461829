"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = PoliticalComp;
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const store_1 = require("@/store");
function PoliticalComp({ info }) {
    const t = (0, store_1.useTranslation)();
    const { quality, political_scope_min, political_scope_max } = info;
    const conf = (0, react_1.useMemo)(() => {
        const widthAbs = (political_scope_max - political_scope_min) * 10;
        const posAbs = (political_scope_min + 5) * 10;
        const center = (political_scope_max + political_scope_min) / 2;
        let colorQuality;
        if (quality < 2) {
            colorQuality = 'red';
        }
        else if (quality < 3.5) {
            colorQuality = 'orange';
        }
        else {
            colorQuality = 'green';
        }
        return {
            widthAbs,
            posAbs,
            center,
            colorQuality,
        };
    }, [political_scope_max, political_scope_min, quality]);
    return (react_1.default.createElement(react_components_1.Grid, { flexC: true, style: { width: 300 }, gap: 12 },
        react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true, className: "glx-pol-comp", gap: 24 },
            react_1.default.createElement("div", { className: "glx-pol-comp-bar" },
                react_1.default.createElement("div", { className: "glx-pol--bar", style: {
                        width: `${conf.widthAbs}%`,
                        left: `${conf.posAbs}%`,
                    } }),
                react_1.default.createElement("div", { className: "glx-pol--line-center" }),
                react_1.default.createElement("div", { className: "glx-pol--line-r1" }),
                react_1.default.createElement("div", { className: "glx-pol--line-r2" }),
                react_1.default.createElement("div", { className: "glx-pol--line-l1" }),
                react_1.default.createElement("div", { className: "glx-pol--line-l2" }),
                react_1.default.createElement(react_components_1.Grid, { className: "glx-py-8 glx-no-select", flex: true, flexR: true, flexSpaceB: true },
                    react_1.default.createElement("div", null, t.get('page.reader.political.left')),
                    react_1.default.createElement("div", null, t.get('page.reader.political.leaning')),
                    react_1.default.createElement("div", null, t.get('page.reader.political.right'))))),
        react_1.default.createElement("br", null),
        react_1.default.createElement(react_components_1.Grid, null,
            react_1.default.createElement(react_components_1.Usage, { label: `${t.get('page.reader.political.quality')} ${quality}`, value: quality * 20, colors: [conf.colorQuality, conf.colorQuality, conf.colorQuality] }))));
}
