"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ConditionBlock;
const react_1 = require("react");
const react_components_1 = require("@grandlinex/react-components");
const store_1 = require("@/store");
const TabStore_1 = require("@/store/TabStore");
function ConditionBlock({ flag, feature, children, }) {
    const features = (0, store_1.useAppSelector)(TabStore_1.selectFeatures);
    const show = (0, react_1.useMemo)(() => {
        let fe = true;
        let fl = true;
        if (!flag && !feature) {
            return false;
        }
        if (flag) {
            fl = react_components_1.LocalStorage.flagLoad(flag);
        }
        if (feature) {
            fe = features.includes(feature);
        }
        return fl && fe;
    }, [flag, feature, features]);
    if (!show) {
        return null;
    }
    return children;
}
