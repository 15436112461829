"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lib_1 = require("@/lib");
const ModAction_1 = require("../ModAction");
const mod = {
    name: 'modules',
    description: 'Modules',
    icon: 'IOBusiness',
    menu: 'top',
    panel: lib_1.PanelViews.ORGANIZER,
    action: ModAction_1.AddPanelAction,
    disabled: (t, data) => !data.org || data.org.length === 0,
};
exports.default = mod;
