"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = useSaveTabHook;
const react_1 = require("react");
const react_components_1 = require("@grandlinex/react-components");
const store_1 = require("@/store");
const TabStore_1 = require("@/store/TabStore");
function useSaveTabHook() {
    const left = (0, store_1.useAppSelector)(TabStore_1.selectTabsLeft);
    const right = (0, store_1.useAppSelector)(TabStore_1.selectTabsRight);
    const curLeft = (0, store_1.useAppSelector)(TabStore_1.selectCurrentTabLeft);
    const curRight = (0, store_1.useAppSelector)(TabStore_1.selectCurrentTabRight);
    return (0, react_1.useEffect)(() => {
        const listener = () => {
            const isDraft = react_components_1.LocalStorage.flagLoad('cache_tab');
            if (isDraft) {
                react_components_1.LocalStorage.jsonSave('tab_data', {
                    tabsLeft: left,
                    tabsRight: right,
                    currentTabLeft: curLeft,
                    currentTabRight: curRight,
                });
            }
            else {
                react_components_1.LocalStorage.delete('tab_data');
            }
        };
        window.addEventListener('beforeunload', listener);
        return () => {
            window.removeEventListener('beforeunload', listener);
        };
    });
}
