"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenLauncher = exports.BaseCommand = exports.AddPanelAction = exports.AddTabAction = void 0;
const react_components_1 = require("@grandlinex/react-components");
const TabStore_1 = require("@/store/TabStore");
const FullFHandler_1 = require("@/context/FullFHandler");
const AddTabAction = (dispatch, t, data) => {
    if (data.view) {
        dispatch((0, TabStore_1.addTab)({
            item: {
                key: (0, react_components_1.uuid)(),
                view: data.view,
                titel: t.get(`tab.name.${data.name}`),
                icon: data.icon,
                data: undefined,
            },
        }));
    }
};
exports.AddTabAction = AddTabAction;
const AddPanelAction = (dispatch, t, data) => {
    if (data.panel) {
        dispatch((0, TabStore_1.setSidePanel)(data.panel));
    }
};
exports.AddPanelAction = AddPanelAction;
const BaseCommand = (dispatch, t, data, context) => {
    data.mod.action(dispatch, t, data.mod, context);
    data.data.close();
    return null;
};
exports.BaseCommand = BaseCommand;
const OpenLauncher = (dispatch, t, data, context) => {
    if ((0, FullFHandler_1.isFullContext)(context) && data.meta?.launcher) {
        context.openLauncher(data.meta.launcher);
        console.log('OpenLauncher', data.meta.launcher);
    }
};
exports.OpenLauncher = OpenLauncher;
