"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabView = exports.MEDIATYPE = exports.PanelViews = exports.Views = exports.ResourceTypeEnum = exports.MediaTypeEnum = void 0;
exports.isFeed = isFeed;
var MediaTypeEnum;
(function (MediaTypeEnum) {
    MediaTypeEnum["VIDEO"] = "VIDEO";
    MediaTypeEnum["IMAGE"] = "IMAGE";
    MediaTypeEnum["MUSIC"] = "MUSIC";
    MediaTypeEnum["HTML"] = "HTML";
    MediaTypeEnum["TEXT"] = "TEXT";
    MediaTypeEnum["PODCAST"] = "PODCAST";
    MediaTypeEnum["LINK"] = "LINK";
})(MediaTypeEnum || (exports.MediaTypeEnum = MediaTypeEnum = {}));
var ResourceTypeEnum;
(function (ResourceTypeEnum) {
    ResourceTypeEnum["YOUTUBE"] = "YOUTUBE";
    ResourceTypeEnum["PODCAST"] = "PODCAST";
    ResourceTypeEnum["NEWS"] = "NEWS";
    ResourceTypeEnum["GAMES"] = "GAMES";
})(ResourceTypeEnum || (exports.ResourceTypeEnum = ResourceTypeEnum = {}));
var Views;
(function (Views) {
    Views[Views["LOGIN"] = 0] = "LOGIN";
    Views[Views["HOME"] = 1] = "HOME";
})(Views || (exports.Views = Views = {}));
var PanelViews;
(function (PanelViews) {
    PanelViews["SEARCH"] = "SEARCH";
    PanelViews["ORGANIZER"] = "ORGANIZER";
    PanelViews["NOTIFICATION"] = "NOTIFICATION";
    PanelViews["PLAYLIST"] = "PLAYLIST";
    PanelViews["USER"] = "USER";
})(PanelViews || (exports.PanelViews = PanelViews = {}));
var MEDIATYPE;
(function (MEDIATYPE) {
    MEDIATYPE[MEDIATYPE["music"] = 0] = "music";
    MEDIATYPE[MEDIATYPE["video"] = 1] = "video";
})(MEDIATYPE || (exports.MEDIATYPE = MEDIATYPE = {}));
var TabView;
(function (TabView) {
    TabView["HOME"] = "HOME";
    TabView["SETTINGS"] = "SETTINGS";
    TabView["SETTINGS_CALENDAR"] = "SETTINGS_CALENDAR";
    TabView["SETTINGS_CLIENT"] = "SETTINGS_CLIENT";
    TabView["SETTINGS_DESKTOP"] = "SETTINGS_DESKTOP";
    TabView["SETTINGS_GAMES"] = "SETTINGS_GAMES";
    TabView["SETTINGS_INFO"] = "SETTINGS_INFO";
    TabView["SETTINGS_SHORTCUTS"] = "SETTINGS_SHORTCUTS";
    TabView["SETTINGS_SMART_HOME"] = "SETTINGS_SMART_HOME";
    TabView["SETTINGS_TABS"] = "SETTINGS_TABS";
    TabView["SETTINGS_TIMER"] = "SETTINGS_TIMER";
    TabView["SETTINGS_WEATHER"] = "SETTINGS_WEATHER";
    TabView["CALENDAR"] = "CALENDAR";
    TabView["ADMIN"] = "ADMIN";
    TabView["ADMIN_INFO"] = "ADMIN_INFO";
    TabView["ADMIN_ORG"] = "ADMIN_ORG";
    TabView["ADMIN_FEED"] = "ADMIN_FEED";
    TabView["ADMIN_FEED_STRATEGY"] = "ADMIN_FEED_STRATEGY";
    TabView["ADMIN_TWITCH"] = "ADMIN_TWITCH";
    TabView["READER"] = "READER";
    TabView["MREADER"] = "MREADER";
    TabView["FEED_LIST"] = "FEED_LIST";
    TabView["IFRAME"] = "IFRAME";
    TabView["SENTIMENT"] = "SENTIMENT";
    TabView["TASK_OVERVIEW"] = "TASK_OVERVIEW";
    TabView["ORG_OVERVIEW"] = "ORG_OVERVIEW";
    TabView["ORG_SETTINGS_GENERAL"] = "ORG_SETTINGS_GENERAL";
    TabView["ORG_SETTINGS_EMPLOYEE"] = "ORG_SETTINGS_EMPLOYEE";
    TabView["ORG_SETTINGS_TEAMS"] = "ORG_SETTINGS_TEAMS";
    TabView["TEAM_OVERVIEW"] = "TEAM_OVERVIEW";
    TabView["TEAM_SETTINGS_MEMBER"] = "TEAM_SETTINGS_MEMBER";
    TabView["TEAM_SETTINGS_PROJECT"] = "TEAM_SETTINGS_PROJECT";
    TabView["TEAM_SETTINGS_TAGS"] = "TEAM_SETTINGS_TAGS";
    TabView["PROJECT_SETTINGS_BOARD"] = "PROJECT_SETTINGS_BOARD";
    TabView["PROJECT_OVERVIEW"] = "PROJECT_OVERVIEW";
    TabView["PROJECT_ELEMENT_LIST"] = "PROJECT_ELEMENT_LIST";
    TabView["KANBAN_BOARD"] = "KANBAN_BOARD";
    TabView["KANBAN_BOARD_SINGLE"] = "KANBAN_BOARD_SINGLE";
    TabView["KANBAN_BOARD_IMPORT"] = "KANBAN_BOARD_IMPORT";
    TabView["KANBAN_BOARD_CALENDAR"] = "KANBAN_BOARD_CALENDAR";
    TabView["KANBAN_BOARD_TIMELINE"] = "KANBAN_BOARD_TIMELINE";
    TabView["EDITOR"] = "EDITOR";
    TabView["WATCH"] = "WATCH";
    TabView["GAMES"] = "GAMES";
    TabView["DOWNLOAD"] = "DOWNLOAD";
})(TabView || (exports.TabView = TabView = {}));
function isFeed(data) {
    if (typeof data === 'object') {
        const dat = data;
        return dat?.info !== undefined;
    }
    return false;
}
