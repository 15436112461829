"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_page_essentials_1 = require("@2spb/react-page-essentials");
const mod = {
    name: 'board',
    description: 'Board',
    icon: 'IOApps',
    menu: 'bot',
    action: () => {
        window.open((0, react_page_essentials_1.Redirect)('home'), '_blank');
    },
    restriction: {
        context: 'web',
    },
};
exports.default = mod;
