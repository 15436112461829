"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrgTreeResolver = void 0;
exports.useOrgTreeResolver = useOrgTreeResolver;
exports.TaskGridCard = TaskGridCard;
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const react_toastify_1 = require("react-toastify");
const GlobalContext_1 = require("@/context/GlobalContext");
const store_1 = require("@/store");
const TabStore_1 = require("@/store/TabStore");
const lib_1 = require("@/lib");
class OrgTreeResolver {
    static notFound = 'Name not found';
    map;
    orgMap;
    constructor(props) {
        this.map = props?.map || new Map();
        this.orgMap = new Map();
        if (props?.org) {
            this.setOrg(props.org);
        }
    }
    setOrg(org) {
        this.orgMap.clear();
        org.forEach((o) => {
            this.orgMap.set(o.e_id, o);
        });
    }
    add(org, data) {
        return this.map.set(org, data);
    }
    has(org) {
        return this.map.has(org);
    }
    resolveProjectName(orgID, id) {
        const tree = this.map.get(orgID);
        if (tree) {
            for (const leaf of tree) {
                const candidate = leaf.project.find((p) => p.e_id === id);
                if (candidate) {
                    return candidate.title;
                }
            }
        }
        return OrgTreeResolver.notFound;
    }
    resolveOrgName(orgID) {
        const tree = this.orgMap.get(orgID);
        if (tree) {
            return tree.title;
        }
        return OrgTreeResolver.notFound;
    }
    resolveList(orgID, id) {
        const tree = this.map.get(orgID);
        if (tree) {
            for (const leaf of tree) {
                for (const project of leaf.project) {
                    for (const board of project.board) {
                        const list = board.lists.find((l) => l.e_id === id);
                        if (list) {
                            return {
                                team: leaf,
                                project,
                                board: board.board,
                                list,
                            };
                        }
                    }
                }
            }
        }
        return null;
    }
}
exports.OrgTreeResolver = OrgTreeResolver;
function useOrgTreeResolver() {
    const context = (0, GlobalContext_1.useGlobalContext)();
    const orgArr = (0, store_1.useAppSelector)(store_1.selectOrg);
    const t = (0, store_1.useAppSelector)(store_1.selectTrans);
    const dispatch = (0, store_1.useAppDispatch)();
    const [orgResolver] = (0, react_1.useState)(new OrgTreeResolver({ org: orgArr || undefined }));
    (0, react_1.useEffect)(() => {
        if (orgArr) {
            orgResolver.setOrg(orgArr);
        }
    }, [orgResolver, orgArr]);
    async function orgLoad(org, force = false) {
        if (!orgResolver.has(org) || force) {
            const p = await context.orgTree(org);
            if (p.success && p.data) {
                orgResolver.add(org, p.data);
            }
        }
    }
    async function openBoard(org, list, element = '') {
        const p = orgResolver.resolveList(org, list);
        if (p) {
            dispatch((0, TabStore_1.addTab)({
                item: {
                    key: (0, react_components_1.uuid)(),
                    data: {
                        org,
                        team: p.team.e_id,
                        project: p.project.e_id,
                        key: p.board.e_id,
                        text: p.board.title,
                        board_search: element,
                        show_child: true,
                    },
                    view: lib_1.TabView.KANBAN_BOARD,
                    icon: 'IOGrid',
                    titel: p.board.title,
                },
            }));
            return;
        }
        react_toastify_1.toast.error(t.get('global.message.error'));
    }
    return { orgResolver, orgLoad, openBoard };
}
function TaskGridCard(props) {
    const { projectName, children, d2 } = props;
    return (react_1.default.createElement("div", { className: (0, react_components_1.cnx)([!!d2, 'glx-statistic-card-d2', 'glx-statistic-card']) },
        react_1.default.createElement("div", { className: "glx-flex-column glx-flex-center glx-h-full" },
            projectName ? react_1.default.createElement("h4", null, projectName) : null,
            children)));
}
