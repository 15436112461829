"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectControls = exports.selectCurrentItemG = exports.selectVolG = exports.selectMPOpen = exports.selectPlG = exports.selectOpenPlaylist = exports.loadPlaylistGlobal = exports.updatePlayListG = exports.togglePlaylist = exports.setControls = exports.setPlaylistOpenG = exports.setCurrentItemG = exports.addToPlayListG = exports.setPlayListClearG = exports.setVolumeG = exports.setPlayListG = exports.playerState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_components_1 = require("@grandlinex/react-components");
const initPlayerState = {
    playlist: null,
    volume: 0.8,
    current: 0,
    controls: react_components_1.LocalStorage.flagLoad('player_controls'),
    openPlaylist: false,
};
exports.playerState = (0, toolkit_1.createSlice)({
    name: 'player',
    initialState: initPlayerState,
    reducers: {
        setPlayListG: (state, action) => {
            if (state.playlist) {
                state.playlist.data = action.payload;
            }
            else {
                state.playlist = {
                    data: action.payload,
                };
            }
            if (state.current >= (state.playlist.data || []).length) {
                state.current = 0;
            }
        },
        updatePlayListG: (state, action) => {
            if (state.playlist && state.playlist.data) {
                const index = state.playlist.data.findIndex((x) => x.id === action.payload.id);
                if (index >= 0) {
                    state.playlist.data[index] = action.payload;
                }
            }
        },
        addToPlayListG: (state, action) => {
            if (state.playlist === null) {
                state.playlist = {
                    data: action.payload,
                };
                state.current = 0;
            }
            else {
                state.playlist.data = (state.playlist.data || []).concat(action.payload);
            }
        },
        setPlayListClearG: (state) => {
            state.playlist = null;
            state.current = 0;
            state.openPlaylist = false;
        },
        setVolumeG: (state, action) => {
            state.volume = action.payload;
        },
        setControls: (state, action) => {
            state.controls = action.payload;
        },
        setCurrentItemG: (state, action) => {
            if (action.payload < 0) {
                state.current = 0;
            }
            else if (!state.playlist?.data) {
                state.current = 0;
            }
            else if (action.payload >= state.playlist.data.length) {
                state.current =
                    state.playlist.data.length === 0 ? 0 : state.playlist.data.length - 1;
            }
            else {
                state.current = action.payload;
            }
        },
        setPlaylistOpenG: (state, action) => {
            state.openPlaylist = action.payload;
        },
        togglePlaylist: (state) => {
            state.openPlaylist = !state.openPlaylist;
        },
        loadPlaylistGlobal: (state, action) => {
            state.playlist = action.payload;
        },
    },
});
_a = exports.playerState.actions, exports.setPlayListG = _a.setPlayListG, exports.setVolumeG = _a.setVolumeG, exports.setPlayListClearG = _a.setPlayListClearG, exports.addToPlayListG = _a.addToPlayListG, exports.setCurrentItemG = _a.setCurrentItemG, exports.setPlaylistOpenG = _a.setPlaylistOpenG, exports.setControls = _a.setControls, exports.togglePlaylist = _a.togglePlaylist, exports.updatePlayListG = _a.updatePlayListG, exports.loadPlaylistGlobal = _a.loadPlaylistGlobal;
const selectOpenPlaylist = (state) => state.player.openPlaylist;
exports.selectOpenPlaylist = selectOpenPlaylist;
const selectPlG = (state) => state.player.playlist;
exports.selectPlG = selectPlG;
const selectMPOpen = (state) => state.player.playlist && (state.player.playlist.data || []).length > 0;
exports.selectMPOpen = selectMPOpen;
const selectVolG = (state) => state.player.volume;
exports.selectVolG = selectVolG;
const selectCurrentItemG = (state) => state.player.current;
exports.selectCurrentItemG = selectCurrentItemG;
const selectControls = (state) => state.player.controls;
exports.selectControls = selectControls;
