"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameStoreType = void 0;
var GameStoreType;
(function (GameStoreType) {
    GameStoreType["STEAM"] = "STEAM";
    GameStoreType["EPIC_GAMES"] = "EPIC";
    GameStoreType["BATTLE_NET"] = "BATTLENET";
    GameStoreType["UPLAY"] = "UPLAY";
    GameStoreType["CUSTOM"] = "CUSTOM";
})(GameStoreType || (exports.GameStoreType = GameStoreType = {}));
