"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extMap = void 0;
exports.download = download;
exports.isVideoEl = isVideoEl;
exports.isAudioEl = isAudioEl;
const react_toastify_1 = require("react-toastify");
exports.extMap = new Map([
    ['audio/mpeg', 'mp3'],
    ['text/plain', 'txt'],
    ['video/mp4', 'mp4'],
    ['video/mpeg', 'mpeg'],
    ['audio/aac', 'aac'],
]);
async function download(url, title) {
    try {
        const resp = await fetch(url);
        const blob = await resp.blob();
        const fileTitle = title.replace(/[^a-zA-Z0-9.]/g, '_');
        const ext = exports.extMap.get(blob.type) || '.blob';
        const dUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('href', dUrl);
        link.setAttribute('download', `${fileTitle}.${ext}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    catch (e) {
        react_toastify_1.toast.error("Can't download file");
    }
}
function isVideoEl(x) {
    return typeof x === 'object' && x?.nodeName === 'VIDEO';
}
function isAudioEl(x) {
    return typeof x === 'object' && x?.nodeName === 'AUDIO';
}
