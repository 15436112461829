"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = useGameFilter;
const react_1 = require("react");
const store_1 = require("@/store");
const GlobalContext_1 = require("@/context/GlobalContext");
const GameLib_1 = require("@/lib/GameLib");
function useGameFilter(init) {
    const context = (0, GlobalContext_1.useGlobalContext)();
    const [formState, setFormState] = (0, react_1.useState)(init ?? {
        search: '',
        disabled: false,
        installed: true,
        order: 'last_played',
        store: 'all',
    });
    const [games, , reload] = (0, store_1.useTabQData)('games', async () => {
        return context.getGameStoreGames();
    });
    const total = (0, react_1.useMemo)(() => {
        let wish = 0;
        let game = 0;
        games?.forEach((g) => {
            if (g.wish_list) {
                wish++;
            }
            else {
                game++;
            }
        });
        return {
            wish,
            game,
        };
    }, [games]);
    const ordered = (0, react_1.useMemo)(() => {
        if (!games) {
            return null;
        }
        const f = games.filter((game) => {
            const dis = !game.disabled || formState.disabled;
            const installed = game.wish_list ||
                !!game.installed === formState.installed ||
                !formState.installed;
            const search = !formState.search ||
                game.game_name.toLowerCase().includes(formState.search.toLowerCase());
            return dis && search && installed;
        });
        switch (formState.order) {
            case 'name_dsc':
                return f.reverse();
            case 'last_played':
                return f.sort((a, b) => {
                    return (b.last_played || 0) - (a.last_played || 0);
                });
            case 'played_time':
                return f.sort((a, b) => {
                    return ((b.meta?.playtime_forever || 0) - (a.meta?.playtime_forever || 0));
                });
            case 'name_asc':
            default:
                return f;
        }
    }, [formState, games]);
    const filtered = (0, react_1.useMemo)(() => {
        if (!ordered) {
            return null;
        }
        const game = ordered
            .filter((g) => !g.meta || !g.wish_list)
            .filter((g) => {
            switch (formState.store) {
                case 'steam':
                    return g.type === GameLib_1.GameStoreType.STEAM;
                case 'epic':
                    return g.type === GameLib_1.GameStoreType.EPIC_GAMES;
                case 'uplay':
                    return g.type === GameLib_1.GameStoreType.UPLAY;
                case 'bnet':
                    return g.type === GameLib_1.GameStoreType.BATTLE_NET;
                case 'custom':
                    return g.type === GameLib_1.GameStoreType.CUSTOM;
                case 'all':
                default:
                    return true;
            }
        });
        const wishList = ordered.filter((g) => g.wish_list);
        return {
            game,
            wishList,
        };
    }, [ordered]);
    return {
        filtered,
        formState,
        setFormState,
        reload,
        total,
    };
}
