"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectHasFritz = exports.selectLevel = exports.setFritz = exports.setLevel = exports.fritzState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialEnv = {
    level: 0,
    hasFritz: false,
};
exports.fritzState = (0, toolkit_1.createSlice)({
    name: 'fritz',
    initialState: initialEnv,
    reducers: {
        setLevel: (state, action) => {
            state.level = action.payload;
        },
        setFritz: (state, action) => {
            state.hasFritz = action.payload;
        },
    },
});
_a = exports.fritzState.actions, exports.setLevel = _a.setLevel, exports.setFritz = _a.setFritz;
const selectLevel = (state) => state.fritz.level;
exports.selectLevel = selectLevel;
const selectHasFritz = (state) => state.fritz.hasFritz;
exports.selectHasFritz = selectHasFritz;
