"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = useAppPreload;
const react_1 = require("react");
const react_components_1 = require("@grandlinex/react-components");
const TabStore_1 = require("@/store/TabStore");
const EchoStore_1 = require("@/store/EchoStore");
const FullFHandler_1 = require("@/context/FullFHandler");
const FritzStore_1 = require("@/store/FritzStore");
const FeedUtil_1 = require("@/utils/FeedUtil");
const hooks_1 = require("@/store/hooks");
const GlobalContext_1 = require("@/context/GlobalContext");
function useAppPreload() {
    const dispatch = (0, hooks_1.useAppDispatch)();
    const context = (0, GlobalContext_1.useGlobalContext)();
    const search = (0, hooks_1.useAppSelector)(EchoStore_1.selectFeedSearch);
    const feedType = (0, hooks_1.useAppSelector)(EchoStore_1.selectFeedType);
    const feedChanel = (0, hooks_1.useAppSelector)(EchoStore_1.selectFeedChanel);
    const query = (0, react_components_1.usePathQueryMap)();
    const updateOrgTree = (0, react_1.useCallback)(async () => {
        const notif = await context.fullTree();
        if (notif.success && notif.data) {
            dispatch((0, EchoStore_1.setOrg)(notif.data));
        }
    }, [context]);
    const updateFeed = (0, react_1.useCallback)(() => {
        const dSearch = query.get('s') || search;
        if (feedType === 'TWITCH') {
            context.listMyTwitchChannel('true').then((vod) => {
                if (vod.success && vod.data) {
                    dispatch((0, EchoStore_1.setEndOfFeed)(true));
                    dispatch((0, EchoStore_1.setFeedList)((0, FeedUtil_1.convertVODS)(vod.data)));
                    dispatch((0, EchoStore_1.setFeedPage)(0));
                }
            });
        }
        else {
            context.feedGet(dSearch, '0', feedChanel, feedType).then((feed) => {
                if (feed.success && feed.data) {
                    if (feed.data.length < 25) {
                        dispatch((0, EchoStore_1.setEndOfFeed)(true));
                    }
                    dispatch((0, EchoStore_1.setFeedList)(feed.data));
                    dispatch((0, EchoStore_1.setFeedPage)(0));
                }
            });
        }
    }, [context, feedChanel, feedType, query, search]);
    const appPreload = (0, react_1.useCallback)(async () => {
        context.getFeatures().then((e) => {
            dispatch((0, TabStore_1.setFeatures)(e));
        });
        context.feedGetList().then((e) => {
            dispatch((0, EchoStore_1.setFeedRes)(e.data));
        });
        context
            .weatherUserGet()
            .then((weatherD) => {
            if (weatherD.success && weatherD.data) {
                context
                    .weatherDataGet(weatherD.data.lat, weatherD.data.lon)
                    .then((weather) => {
                    dispatch((0, EchoStore_1.setWeather)(weather.data));
                });
            }
        })
            .catch(console.error);
        if ((0, FullFHandler_1.isFullContext)(context)) {
            context.fGetLevel().then((level) => {
                dispatch((0, FritzStore_1.setLevel)(level));
            });
            context.getFritz().then((ff) => {
                dispatch((0, FritzStore_1.setFritz)(!!ff));
            });
            context.getCustomTabs().then((ff) => {
                dispatch((0, TabStore_1.setCustomTab)(ff));
            });
        }
        context
            .listMyTwitchChannel()
            .then((e) => {
            dispatch((0, EchoStore_1.setTwitch)(e.data || []));
        })
            .catch(console.error);
        context
            .getNotificationList()
            .then((notif) => {
            if (notif.success && notif.data) {
                dispatch((0, EchoStore_1.setNotification)(notif.data));
            }
        })
            .catch(console.error);
        updateFeed();
        await updateOrgTree();
    }, [context, updateFeed, updateOrgTree]);
    return {
        appPreload,
        updateFeed,
        updateOrgTree,
    };
}
