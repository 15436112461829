"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const react_toastify_1 = require("react-toastify");
const store_1 = require("@/store");
const TabStore_1 = require("@/store/TabStore");
const PlayerStore_1 = require("@/store/PlayerStore");
const GlobalContext_1 = require("@/context/GlobalContext");
function SettingsPanel() {
    const context = (0, GlobalContext_1.useGlobalContext)();
    const t = (0, store_1.useAppSelector)(store_1.selectTrans);
    const dispatch = (0, store_1.useAppDispatch)();
    const controls = (0, store_1.useAppSelector)(PlayerStore_1.selectControls);
    const counter = (0, store_1.useAppSelector)(TabStore_1.selectUpdateCounter);
    const saveTab = (0, store_1.useAppSelector)(TabStore_1.selectSaveTab);
    const [cityFind, setCityFind] = (0, react_1.useState)(null);
    const [wInfo, , reloadWInfo] = (0, react_components_1.useQData)(async () => {
        const conf = (await context.weatherUserGet()).data;
        const data = conf
            ? (await context.weatherDataGet(conf.lat, conf.lon)).data
            : null;
        if (data) {
            dispatch((0, store_1.setWeather)(data));
        }
        return {
            conf,
            data,
        };
    });
    const rowData = (0, react_1.useMemo)(() => {
        const base = [
            {
                keys: ['Alt', 'H'],
                description: t.get('page.settings.hotkey.description.home'),
            },
            {
                keys: ['Alt', 'S'],
                description: t.get('page.settings.hotkey.description.settings'),
            },
            {
                keys: ['Alt', 'W'],
                description: t.get('page.settings.hotkey.description.close.tab'),
            },
            {
                keys: ['Alt', 'V'],
                description: t.get('page.settings.hotkey.description.auto.reload'),
            },
            {
                keys: ['Ctrl', 'Space'],
                description: t.get('page.settings.hotkey.description.open.spotlight'),
            },
            {
                keys: ['.'],
                description: t.get('page.settings.hotkey.description.player.back'),
            },
            {
                keys: [','],
                description: t.get('page.settings.hotkey.description.player.forward'),
            },
            {
                keys: ['Alt', 'N'],
                description: t.get('page.settings.hotkey.description.player.last'),
            },
            {
                keys: ['Alt', 'M'],
                description: t.get('page.settings.hotkey.description.player.next'),
            },
        ];
        return base;
    }, [t]);
    return (react_1.default.createElement(react_components_1.PortalStepper, { offset: 100, className: "glx-layout-block-large glx-mt-12 glx-h-full-12", conf: [
            {
                key: 'base',
                name: t.get('page.settings.part.general'),
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true },
                    react_1.default.createElement("div", null,
                        "App: v",
                        (0, react_components_1.getDocumentMeta)('REACT_APP_VERSION')))),
            },
            {
                key: 'general',
                name: t.get('page.settings.title.general'),
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true, vCenter: true, flexC: true, gap: 8 },
                    react_1.default.createElement(react_components_1.Form, { className: "glx-min-w-400", defaultState: {
                            cache: saveTab,
                            counter: !!counter,
                            native: controls,
                        }, onChange: ({ form }) => {
                            dispatch((0, TabStore_1.setSaveTab)(form.cache));
                            react_components_1.LocalStorage.flagSave('cache_tab', form.cache);
                            dispatch((0, TabStore_1.setUpdateCounter)(form.counter ? 240 : null));
                            dispatch((0, PlayerStore_1.setControls)(form.native));
                            react_components_1.LocalStorage.flagSave('player_controls', form.native);
                        }, options: [
                            [
                                {
                                    key: 'cache',
                                    type: react_components_1.InputOptionType.CHECKBOX,
                                    label: t.get('page.settings.cache.tabs'),
                                },
                                {
                                    key: 'native',
                                    type: react_components_1.InputOptionType.CHECKBOX,
                                    label: t.get('page.settings.player.native.toolbar'),
                                },
                                {
                                    key: 'counter',
                                    type: react_components_1.InputOptionType.CHECKBOX,
                                    label: t.get('page.settings.auto.update.feed'),
                                },
                            ],
                        ] }))),
            },
            {
                key: 'weather',
                name: t.get('page.settings.title.weather'),
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true, vCenter: true, flexC: true, gap: 8 },
                    react_1.default.createElement(react_components_1.Grid, { className: "glx-min-w-400 glx-max-w-800", gap: 8 },
                        react_1.default.createElement("p", null, `${t.get(`page.settings.label.weather.current`)}: ${wInfo?.data?.city.name}`),
                        !wInfo?.data?.city ? (react_1.default.createElement("p", null, t.get(`global.message.invalid`))) : null,
                        wInfo !== undefined ? (react_1.default.createElement(react_components_1.Form, { options: [
                                [
                                    {
                                        required: true,
                                        key: 'name',
                                        type: react_components_1.InputOptionType.TEXT,
                                        label: t.get('form.weather.city.label'),
                                    },
                                ],
                            ], loading: true, buttonText: t.get('global.button.search'), onSubmit: async ({ form, validateRequired }) => {
                                if (!validateRequired(true)) {
                                    const data = await context.weatherCitySearch(form.name);
                                    if (data?.success) {
                                        setCityFind(data.data);
                                    }
                                    else {
                                        react_toastify_1.toast.error(t.get('global.error.notfound'));
                                    }
                                }
                            } })) : null,
                        cityFind ? (react_1.default.createElement(react_components_1.Table, { rowData: cityFind, columnDefs: [
                                {
                                    dataType: 'string',
                                    headerName: 'City',
                                    field: 'name',
                                    cellRenderer: (d) => {
                                        return (react_1.default.createElement("div", null,
                                            react_1.default.createElement("div", null,
                                                d.data.name,
                                                ", ",
                                                d.data.country),
                                            react_1.default.createElement("div", null, d.data.state)));
                                    },
                                },
                            ], rowAction: [
                                (dat) => {
                                    return {
                                        name: 'Select',
                                        icon: react_1.default.createElement(react_components_1.IOCheckmarkDone, null),
                                        onClick: () => {
                                            context
                                                .weatherUserSet({
                                                lon: `${dat.data.lon}`,
                                                lat: `${dat.data.lat}`,
                                            })
                                                .then((dx) => {
                                                if (dx.success) {
                                                    react_toastify_1.toast.success(t.get('global.message.success'));
                                                    setCityFind(null);
                                                    reloadWInfo();
                                                }
                                                else {
                                                    react_toastify_1.toast.error(t.get('global.error.notfound'));
                                                }
                                            });
                                        },
                                    };
                                },
                            ] })) : null))),
            },
            {
                key: 'shortcuts',
                name: t.get('page.settings.title.shortcuts'),
                collapsed: false,
                render: (react_1.default.createElement(react_components_1.Grid, { flex: true, vCenter: true, flexC: true, gap: 8 },
                    react_1.default.createElement(react_components_1.Table, { className: "glx-min-w-400 glx-max-w-800", rowData: rowData, columnDefs: [
                            {
                                field: 'keys',
                                headerName: 'Keys',
                                cellRenderer: (dat) => {
                                    return (react_1.default.createElement("div", null, dat.data.keys.map((k, i, a) => (react_1.default.createElement(react_1.default.Fragment, null,
                                        react_1.default.createElement("span", { className: "keyboard-shortcut" }, k),
                                        i + 1 < a.length ? ' + ' : null)))));
                                },
                            },
                            {
                                field: 'description',
                                headerName: 'Description',
                                dataType: 'string',
                            },
                        ] }))),
            },
        ] }));
}
exports.default = SettingsPanel;
