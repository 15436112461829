"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.options = void 0;
exports.getScatterData = getScatterData;
exports.default = WeatherForceCast;
const react_1 = __importStar(require("react"));
const react_google_charts_1 = require("react-google-charts");
const react_components_1 = require("@grandlinex/react-components");
const react_page_essentials_1 = require("@2spb/react-page-essentials");
const store_1 = require("@/store");
function getScatterData(data, short = false) {
    const out = [];
    out.push(['Date', 'Min', 'Temp']);
    (short ? data?.list.slice(0, 5) : data?.list)?.forEach((el) => {
        out.push([new Date(el.dt_txt), el.main.temp_min, el.main.temp]);
    });
    return out;
}
exports.options = {
    curveType: 'function',
    legend: {
        position: 'bottom',
        textStyle: {
            color: 'white',
        },
    },
    hAxis: {
        title: 'Time',
        titleTextStyle: {
            color: 'white',
        },
        baselineColor: 'white',
        textStyle: {
            color: 'white',
        },
    },
    vAxis: {
        title: 'Temp °C',
        titleTextStyle: {
            color: 'white',
        },
        baselineColor: 'white',
        textStyle: {
            color: 'white',
        },
    },
    backgroundColor: '#202020',
    chartArea: {
        backgroundColor: '#202020',
    },
};
function WeatherForceCast() {
    const dat = (0, store_1.useAppSelector)(store_1.selectWeather);
    const t = (0, react_page_essentials_1.useTranslation)();
    const [short, setShort] = (0, react_1.useState)(false);
    return (react_1.default.createElement(react_components_1.Card, { icon: "IOSunny", color: "yellow", title: t.get('block.forecast.title') },
        react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true, className: "glx-w-full-4", gap: 24 },
            react_1.default.createElement(react_google_charts_1.Chart, { key: "weaterChart", chartType: "LineChart", width: "100%", height: "300px", data: getScatterData(dat, short), options: exports.options }),
            react_1.default.createElement(react_components_1.ContentSwitcher, { onChange: (e) => {
                    if (e.key === 'full') {
                        setShort(false);
                    }
                    else {
                        setShort(true);
                    }
                }, items: [
                    {
                        key: 'full',
                        name: t.get('block.forecast.switch.full'),
                    },
                    {
                        key: 'day',
                        name: t.get('block.forecast.switch.day'),
                    },
                ] }))));
}
