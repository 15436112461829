"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useKeyHook = useKeyHook;
const react_1 = require("react");
const react_components_1 = require("@grandlinex/react-components");
// eslint-disable-next-line import/prefer-default-export
function useKeyHook() {
    const [formKey, setFormKey] = (0, react_1.useState)((0, react_components_1.uuid)());
    return [
        formKey,
        () => {
            setFormKey((0, react_components_1.uuid)());
        },
    ];
}
