"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const react_toastify_1 = require("react-toastify");
const store_1 = require("@/store");
const GlobalContext_1 = require("@/context/GlobalContext");
const TabStore_1 = require("@/store/TabStore");
const Markdown_1 = require("@/component/Markdown");
const ElementList = function (props) {
    const dispatch = (0, store_1.useAppDispatch)();
    const ref = (0, react_1.createRef)();
    const context = (0, GlobalContext_1.useGlobalContext)();
    const { item } = props;
    const { org, team, project } = item;
    const [data, , reload] = (0, react_components_1.useQData)(async () => {
        return (await context.elementList(org, team, project)).data;
    });
    const [selection, setSelection] = (0, react_1.useState)([]);
    return (react_1.default.createElement("div", { ref: ref, className: "glx-mt-4 glx-w-full-4 glx-h-full-4" },
        react_1.default.createElement("div", { className: "glx-flex-row glx-flex-end glx-flex-g-8" },
            react_1.default.createElement("div", null,
                "Selected: ",
                selection.length),
            react_1.default.createElement(react_components_1.IconButton, { onClick: async () => {
                    dispatch((0, TabStore_1.setBlockingModal)({
                        message: 'Deleting...',
                        progress: `0/${selection?.length || 0}`,
                    }));
                    for (let i = 0; i < selection.length; i++) {
                        try {
                            const r = await context.elementDelete(org, team, selection[i]);
                            if (!r.success) {
                                react_toastify_1.toast.error(selection[i]);
                            }
                        }
                        catch (ex) {
                            console.error(ex);
                        }
                        dispatch((0, TabStore_1.setBlockingModal)({
                            message: 'Deleting...',
                            progress: `${i + 1}/${selection?.length || 0}`,
                        }));
                    }
                    dispatch((0, TabStore_1.setBlockingModal)(null));
                    reload();
                } },
                react_1.default.createElement(react_components_1.IOTrashBin, null))),
        react_1.default.createElement(react_components_1.Table, { isSelectable: "e_id", onSelectionChange: (e) => {
                setSelection(e);
            }, fixedHeader: true, rowData: data?.map((e) => ({
                ...e,
                children: [],
            })) || [], columnDefs: [
                {
                    headerName: 'Title',
                    field: 'title',
                },
                {
                    headerName: 'Description',
                    field: 'description',
                    cellRenderer: (x) => react_1.default.createElement(Markdown_1.MarkdownPreview, null, x.value),
                },
                {
                    headerName: 'ID',
                    field: 'e_id',
                },
                {
                    headerName: 'Parent',
                    field: 'parent',
                },
                {
                    headerName: 'Board',
                    field: 'board',
                },
                {
                    headerName: 'Created by',
                    field: 'created_by',
                },
                {
                    headerName: 'Changed',
                    field: 'changed',
                },
                {
                    headerName: 'Created',
                    field: 'created',
                },
                {
                    headerName: 'Type',
                    field: 'element_type',
                },
                {
                    headerName: 'Complete',
                    field: 'complete',
                },
            ] })));
};
exports.default = ElementList;
