"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = useFeedStrategy;
const react_1 = require("react");
const react_components_1 = require("@grandlinex/react-components");
const react_toastify_1 = require("react-toastify");
const react_page_essentials_1 = require("@2spb/react-page-essentials");
const store_1 = require("@/store");
const TabStore_1 = require("@/store/TabStore");
const GlobalContext_1 = require("@/context/GlobalContext");
const lib_1 = require("@/lib");
const PlayerStore_1 = require("@/store/PlayerStore");
const FeedUtil_1 = require("@/utils/FeedUtil");
function useFeedStrategy(feed, mode) {
    const context = (0, GlobalContext_1.useGlobalContext)();
    const t = (0, store_1.useTranslation)();
    const dispatch = (0, store_1.useAppDispatch)();
    const auth = (0, react_page_essentials_1.useAuthHelper)();
    const feeds = (0, store_1.useAppSelector)(store_1.selectFeedRes);
    return (0, react_1.useMemo)(() => {
        const { entity, info } = feed;
        const { title, created, published, resource, thumbnail, meta, e_id } = entity;
        const { name, type } = info;
        const genFeed = feeds?.find((f) => f.e_id === resource);
        /*    const nLink =
          link && link.url
            ? [
                {
                  onClick: () => {
                    context.openExternalConfig({
                      url: link.url!,
                      external: true,
                    });
                  },
                  icon: 'IOOpenOutline',
                },
              ]
            : []; */
        const getMedia = async (tp) => {
            const ax = await context.feedResolve({ e_id });
            return ax.data?.media.find((m) => m.media_type === tp);
        };
        const mediaButton = (med) => {
            return [
                {
                    onClick: () => {
                        window.open(med.src, '_blank');
                    },
                    icon: 'IOOpenOutline',
                },
                {
                    onClick: () => {
                        dispatch((0, PlayerStore_1.setPlayListG)([
                            {
                                ...med,
                                id: (0, react_components_1.uuid)(),
                            },
                        ]));
                    },
                    icon: 'IOPlay',
                },
                {
                    onClick: () => {
                        dispatch((0, PlayerStore_1.addToPlayListG)([
                            {
                                ...med,
                                id: (0, react_components_1.uuid)(),
                            },
                        ]));
                    },
                    icon: 'IOAddOutline',
                },
            ];
        };
        const mediaButtonRemote = (mediaType, vType = lib_1.MEDIATYPE.music) => {
            return [
                {
                    onClick: () => {
                        getMedia(mediaType).then((media) => {
                            if (media?.url) {
                                window.open(media.url, '_blank');
                            }
                        });
                    },
                    icon: 'IOOpenOutline',
                },
                {
                    onClick: () => {
                        getMedia(mediaType).then((media) => {
                            dispatch((0, PlayerStore_1.setPlayListG)([
                                {
                                    vtype: vType,
                                    src: media?.url || '',
                                    meta: {
                                        artistCreator: name,
                                        img: (0, FeedUtil_1.getCoverImage)(feed, auth, genFeed)?.[0] || undefined,
                                    },
                                    id: '',
                                    title,
                                },
                            ]));
                        });
                    },
                    icon: 'IOPlay',
                },
                {
                    onClick: () => {
                        getMedia(mediaType).then((media) => {
                            dispatch((0, PlayerStore_1.addToPlayListG)([
                                {
                                    vtype: lib_1.MEDIATYPE.music,
                                    src: media?.url || '',
                                    meta: {
                                        artistCreator: name,
                                        img: (0, FeedUtil_1.getCoverImage)(feed, auth, genFeed)?.[0] || undefined,
                                    },
                                    id: '',
                                    title,
                                },
                            ]));
                        });
                    },
                    icon: 'IOAddOutline',
                },
            ];
        };
        let st = { getParameter: {} };
        switch (type) {
            case 'NEWS':
                st = {
                    getParameter: {
                        icon: 'IONewspaper',
                        color: 'orange',
                        hoverButton: [
                            {
                                onClick: () => {
                                    getMedia('LINK').then((media) => {
                                        if (media?.url) {
                                            window.open(media.url, '_blank');
                                        }
                                        else {
                                            react_toastify_1.toast.error(t.get('page.board.label.no.link'));
                                        }
                                    });
                                },
                                icon: 'IOOpenOutline',
                            },
                            {
                                onClick: () => dispatch((0, TabStore_1.addReader)({ item: feed, pos: mode })),
                                icon: 'IOGlassesOutline',
                            },
                        ],
                    },
                    content: title,
                };
                break;
            case 'PODCAST':
                st = {
                    getParameter: {
                        icon: 'IOMicOutline',
                        color: 'green',
                        hoverButton: mediaButtonRemote('PODCAST'),
                    },
                    content: title,
                };
                break;
            case 'YOUTUBE':
                st = {
                    getParameter: {
                        className: 'yt-card',
                        icon: 'IOLogoYoutube',
                        color: 'red',
                        hoverButton: mediaButtonRemote('VIDEO', lib_1.MEDIATYPE.video),
                    },
                    content: title,
                };
                break;
            case 'TWITCH':
                st = {
                    getParameter: {
                        className: 'yt-card',
                        icon: 'IOLogoTwitch',
                        hoverButton: mediaButton({
                            id: '',
                            src: meta.play || '',
                            vtype: lib_1.MEDIATYPE.video,
                            meta: {
                                artistCreator: name,
                                img: thumbnail || undefined,
                            },
                            title,
                        }),
                    },
                    content: title,
                };
                break;
            default:
        }
        st.getParameter.className = `glx-animation-fade-in-super-fast ${st.getParameter.className || ''}`;
        st.getParameter.title = name;
        if (!st.getParameter.imgUrl) {
            st.getParameter.imgUrl = (0, FeedUtil_1.getCoverImage)(feed, auth, genFeed)?.[0];
        }
        if (st.getParameter.iconCover === undefined) {
            st.getParameter.iconCover = true;
        }
        if (st.getParameter.date === undefined) {
            st.getParameter.date = (0, FeedUtil_1.parseDate)(published || created);
        }
        st.getParameter.date += ` • ${feed.info.lang}`;
        st.getParameter.menu = [
            {
                key: 'open',
                icon: 'IOGlassesOutline',
                label: t.get('menu.open.reader'),
                fc: async () => {
                    dispatch((0, TabStore_1.addReader)({ item: feed, pos: mode }));
                },
            },
        ];
        st.getParameter.badges = [
            ...(st.getParameter.badges || []),
            ...(feed.entity.meta.tags?.map((tg) => {
                return {
                    text: t.get(tg.key) + (tg.value ? `: ${tg.value}` : ''),
                    color: tg.color,
                    icon: tg.icon || 'IOPricetags',
                };
            }) || []),
        ];
        if (feed.entity.meta.tags?.find((e) => e.key === 'tags.label.article.full') &&
            st.getParameter.hoverButton) {
            st.getParameter.hoverButton = [...st.getParameter.hoverButton];
        }
        if (genFeed) {
            st.getParameter.menu.push({
                key: 'filter',
                icon: 'IOFilterOutline',
                label: t.get('menu.filter.feed.channel'),
                fc: async () => {
                    dispatch((0, store_1.setFeedSearch)(undefined));
                    dispatch((0, store_1.setFetchNextPage)(false));
                    dispatch((0, store_1.setEndOfFeed)(false));
                    dispatch((0, store_1.setFeedChanel)(genFeed.e_id));
                    dispatch((0, store_1.setFeedList)(null));
                    window._uiEvents.emit('app-update', null);
                },
            });
        }
        return [st.getParameter, st.content];
    }, [feed, feeds]);
}
