"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = PlaylistDropZone;
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const react_toastify_1 = require("react-toastify");
const react_page_essentials_1 = require("@2spb/react-page-essentials");
const DragUtils_1 = require("@/utils/DragUtils");
const PlayerStore_1 = require("@/store/PlayerStore");
const lib_1 = require("@/lib");
const store_1 = require("@/store");
function PlaylistDropZone({ children, className, }) {
    const t = (0, react_page_essentials_1.useTranslation)();
    const dispatch = (0, store_1.useAppDispatch)();
    const [hover, setHover] = (0, react_1.useState)(false);
    return (react_1.default.createElement("div", { className: (0, react_components_1.cnx)([hover, 'drop-hover-active'], className), onDragOver: (e) => {
            e.preventDefault();
        }, onDragEnter: () => {
            setHover(true);
        }, onDragExit: () => {
            setHover(false);
        }, onDrop: (e) => {
            e.preventDefault();
            const linkUrl = (0, DragUtils_1.getUrlFromDrag)(e);
            if (linkUrl) {
                dispatch((0, PlayerStore_1.addToPlayListG)([
                    {
                        id: (0, react_components_1.uuid)(),
                        title: linkUrl.title || linkUrl.url,
                        vtype: lib_1.MEDIATYPE.video,
                        src: linkUrl.url,
                    },
                ]));
                react_toastify_1.toast.success(t.get('message.playlist.add.item'));
            }
            else {
                react_toastify_1.toast.warn(t.get('global.message.error'));
            }
        } }, children));
}
